import { configureStore } from "@reduxjs/toolkit";

import homeSlice from "../reducer/homeSlice";
import searchSlice from "../reducer/searchSlice";
import compareTableSlice from "../reducer/compareTableSlice";
import simulateSlice from "../reducer/simulationSlice";

export const store = configureStore({
  reducer: {
    home: homeSlice,
    search: searchSlice,
    compareTable: compareTableSlice,
    simulate: simulateSlice,
  },
});

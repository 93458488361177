import React, { Suspense } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import AppLayout from "./components/AppLayout";
import Loader from "./components/Common/Loader";
import SUDetails from "./components/Compare/SUDetails";
import SUList from "./components/Compare/SUList";
import NewSearch from "./components/Home/NewSearch";
import SearchHistory from "./components/Home/SearchHistory";

const Home = React.lazy(() => import("./components/Home"));
const Compare = React.lazy(() => import("./components/Compare"));
const Simulate = React.lazy(() => import("./components/Simulate"));
const Glossary = React.lazy(() => import("./components/Glossary"));
const Support = React.lazy(() => import("./components/Support"));

function AppRoutes() {
  return (
    <Routes>
      {/* <Route path="/" element={<AppLayout />}>
        <Route index element={<Navigate to="/home" replace />} />
        <Route
          path="home"
          element={
            <Suspense fallback={<Loader />}>
              <Home />
            </Suspense>
          }>
          <Route index element={<SearchHistory />} />
          <Route path="new-search" element={<NewSearch />} />
        </Route>

        <Route
          path="compare"
          element={
            <Suspense fallback={<Loader />}>
              <Compare />
            </Suspense>
          }>
          <Route
            index
            element={<Navigate to="sourcing-units-list" replace />}
          />
          <Route path="sourcing-units-list" element={<SUList />} />
          <Route path="sourcing-units-details" element={<SUDetails />} />
        </Route>

        <Route
          path="simulate"
          element={
            <Suspense fallback={<Loader />}>
              <Simulate />
            </Suspense>
          }
        />

        <Route
          path="glossary"
          element={
            <Suspense fallback={<Loader />}>
              <Glossary />
            </Suspense>
          }
        />

        <Route
          path="support"
          element={
            <Suspense fallback={<Loader />}>
              <Support />
            </Suspense>
          }
        />

        {/* Using path="*"" means "match anything", so this route
          acts like a catch-all for URLs that we don't have explicit
          routes for. 
        <Route path="*" element={<Navigate to="/" replace />} />
      </Route> */}

      {/* logistics pilot development */}
      <Route path="/" element={<AppLayout />}>
        <Route index element={<Navigate to="/home" replace />} />
        <Route
          path="home"
          element={
            <Suspense fallback={<Loader />}>
              <Home />
            </Suspense>
          }>
          <Route index element={<SearchHistory />} />
          <Route path="new-search" element={<NewSearch />} />
        </Route>

        <Route
          path="compare"
          element={
            <Suspense fallback={<Loader />}>
              <Compare />
            </Suspense>
          }>
          <Route
            index
            element={<Navigate to="sourcing-units-list" replace />}
          />
          <Route path="sourcing-units-list" element={<SUList />} />
          <Route path="sourcing-units-details" element={<SUDetails />} />
        </Route>

        <Route
          path="simulate"
          element={
            <Suspense fallback={<Loader />}>
              <Simulate />
            </Suspense>
          }
        />

        <Route
          path="glossary"
          element={
            <Suspense fallback={<Loader />}>
              <Glossary />
            </Suspense>
          }
        />

        <Route
          path="support"
          element={
            <Suspense fallback={<Loader />}>
              <Support />
            </Suspense>
          }
        />

        {/* Using path="*"" means "match anything", so this route
          acts like a catch-all for URLs that we don't have explicit
          routes for. */}
        <Route path="*" element={<Navigate to="/" replace />} />
      </Route>
    </Routes>
  );
}

export default AppRoutes;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import {
  getDefaultFilterOptionsForDC,
  getDefaultFilterOptionsForSKU,
  getFilterOptionsForDC,
  getFilterOptionsForSKU,
  getFilterOptionsForSKUBySearch,
} from "../../services/searchService";

const initialState = {
  isSKUFiltersLoading: false,
  isSKUFilterOptionsLoading: false,
  isDCFiltersLoading: false,
  isDCFilterOptionsLoading: false,
  skuFilterOptions: {
    Brand: [],
    Category: [],
    CenterRegion: [],
    Country: [],
    Division: [],
    Form: [],
    Product: [],
    SubRegion: [],
    Subsector: [],
  },
  dcFilterOptions: {
    centerRegion: [],
    country: [],
    distributionCenter: [],
    subregion: [],
  },
};

export const fetchDefaultFilterOptionsForSKU = createAsyncThunk(
  "fetch/defaultFilterOptionsForSKU",
  async () => {
    const response = await getDefaultFilterOptionsForSKU();
    if (response.success) {
      return response.data;
    }
    return {};
  }
);

export const fetchFilterOptionsForSKU = createAsyncThunk(
  "fetch/filterOptionsForSKU",
  async (params) => {
    const response = await getFilterOptionsForSKU(params);
    if (response.success) {
      return response.data;
    }
    return {};
  }
);

export const fetchFilterOptionsForSKUBySearch = createAsyncThunk(
  "fetch/filterOptionsForSKUBySearch",
  async (params) => {
    const response = await getFilterOptionsForSKUBySearch(params);
    if (response.success) {
      return response.data;
    }
    return {};
  }
);

export const fetchDefaultFilterOptionsForDC = createAsyncThunk(
  "fetch/defaultFilterOptionsForDC",
  async () => {
    const response = await getDefaultFilterOptionsForDC();
    if (response.success) {
      return response.data;
    }
    return {};
  }
);

export const fetchFilterOptionsForDC = createAsyncThunk(
  "fetch/filterOptionsForDC",
  async (params) => {
    const response = await getFilterOptionsForDC(params);
    if (response.success) {
      return response.data;
    }
    return {};
  }
);

export const searchSlice = createSlice({
  name: "search",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Default SKU Filters Options
    builder.addCase(fetchDefaultFilterOptionsForSKU.pending, (state) => {
      state.isSKUFiltersLoading = true;
    });
    builder.addCase(
      fetchDefaultFilterOptionsForSKU.fulfilled,
      (state, action) => {
        state.isSKUFiltersLoading = false;
        state.skuFilterOptions = action.payload;
      }
    );
    builder.addCase(
      fetchDefaultFilterOptionsForSKU.rejected,
      (state, action) => {
        state.isSKUFiltersLoading = false;
        state.error = action.error.message;
      }
    );

    // SKU Filters Options
    builder.addCase(fetchFilterOptionsForSKU.pending, (state) => {
      state.isSKUFiltersLoading = true;
    });
    builder.addCase(fetchFilterOptionsForSKU.fulfilled, (state, action) => {
      state.isSKUFiltersLoading = false;
      state.skuFilterOptions = action.payload;
    });
    builder.addCase(fetchFilterOptionsForSKU.rejected, (state, action) => {
      state.isSKUFiltersLoading = false;
      state.error = action.error.message;
    });

    // SKU Filters Options by Search
    builder.addCase(fetchFilterOptionsForSKUBySearch.pending, (state) => {
      state.isSKUFilterOptionsLoading = true;
    });
    builder.addCase(
      fetchFilterOptionsForSKUBySearch.fulfilled,
      (state, action) => {
        state.isSKUFilterOptionsLoading = false;
        state.skuFilterOptions = {
          ...state.skuFilterOptions,
          ...action.payload,
        };
      }
    );
    builder.addCase(
      fetchFilterOptionsForSKUBySearch.rejected,
      (state, action) => {
        state.isSKUFilterOptionsLoading = false;
        state.error = action.error.message;
      }
    );

    // Default DC Filters Options
    builder.addCase(fetchDefaultFilterOptionsForDC.pending, (state) => {
      state.isDCFiltersLoading = true;
    });
    builder.addCase(
      fetchDefaultFilterOptionsForDC.fulfilled,
      (state, action) => {
        state.isDCFiltersLoading = false;
        state.dcFilterOptions = action.payload;
      }
    );
    builder.addCase(
      fetchDefaultFilterOptionsForDC.rejected,
      (state, action) => {
        state.isDCFiltersLoading = false;
        state.error = action.error.message;
      }
    );

    // DC Filters Options
    builder.addCase(fetchFilterOptionsForDC.pending, (state) => {
      state.isDCFiltersLoading = true;
    });
    builder.addCase(fetchFilterOptionsForDC.fulfilled, (state, action) => {
      state.isDCFiltersLoading = false;
      state.dcFilterOptions = action.payload;
    });
    builder.addCase(fetchFilterOptionsForDC.rejected, (state, action) => {
      state.isDCFiltersLoading = false;
      state.error = action.error.message;
    });
  },
});

export default searchSlice.reducer;

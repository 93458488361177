import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  getDefaultFilterOptionsForSKU,
  getFilterOptionsForSKUBySearch,
} from "../../services/searchService";
import {
  getAddItemsData,
  getSimulationData,
  getSourcingUnits,
} from "../../services/stimulateService";

const initialState = {
  isSimulationLoading: false,
  isSimulationLoadingRM: false,
  isSimulationLoadingPM: false,
  isSimulationLoadingSPM: false,
  isAddItemRMLoading: false,
  isAddItemPMLoading: false,
  isAddItemSPMLoading: false,
  isRMfilterOptionsLoading: false,
  isPMfilterOptionsLoading: false,
  isSPMfilterOptionsLoading: false,
  isSULoading: false,
  rmFilterOptions: [],
  pmFilterOptions: [],
  spmFilterOptions: [],
  simulationData: { RM: [], PM: [], SPM: [] },
  addItemDataRM: {},
  addItemDataPM: {},
  addItemDataSPM: {},
  sourcingUnits: {
    totalMatchPlant: 0,
    alternativePlant: [],
    plantCode: [],
  },
  showHeader: true,
};

export const fetchSimulationData = createAsyncThunk(
  "fetch/simulationData",
  async (payload) => {
    const response = await getSimulationData(payload);
    if (response.success) {
      response.data.material = payload.material;
      return response.data;
    }
    return {};
  }
);

export const fetchSimulationDataRM = createAsyncThunk(
  "fetch/simulationDataRM",
  async (payload) => {
    const response = await getSimulationData(payload);
    if (response.success) {
      response.data.material = payload.material;
      return response.data;
    }
    return {};
  }
);

export const fetchSimulationDataPM = createAsyncThunk(
  "fetch/simulationDataPM",
  async (payload) => {
    const response = await getSimulationData(payload);
    if (response.success) {
      response.data.material = payload.material;
      return response.data;
    }
    return {};
  }
);

export const fetchSimulationDataSPM = createAsyncThunk(
  "fetch/simulationDataSPM",
  async (payload) => {
    const response = await getSimulationData(payload);
    if (response.success) {
      response.data.material = payload.material;
      return response.data;
    }
    return {};
  }
);

export const fetchAddItemDataRM = createAsyncThunk(
  "fetch/addItemDataRM",
  async (payload) => {
    const response = await getAddItemsData(payload);
    if (response.success) {
      return response.data;
    }
    return {};
  }
);

export const fetchAddItemDataPM = createAsyncThunk(
  "fetch/addItemDataPM",
  async (payload) => {
    const response = await getAddItemsData(payload);
    if (response.success) {
      return response.data;
    }
    return {};
  }
);

export const fetchAddItemDataSPM = createAsyncThunk(
  "fetch/addItemDataSPM",
  async (payload) => {
    const response = await getAddItemsData(payload);
    if (response.success) {
      return response.data;
    }
    return {};
  }
);

export const fetchFilterOptionsForSKU = createAsyncThunk(
  "fetch/filterOptionsForSKU",
  async () => {
    const response = await getDefaultFilterOptionsForSKU();
    if (response.success) {
      return response.data;
    }
    return {};
  }
);

export const fetchFilterOptionsBySearch = createAsyncThunk(
  "fetch/filterOptionsBySearch",
  async (params) => {
    const response = await getFilterOptionsForSKUBySearch(params);
    if (response.success) {
      return response.data;
    }
    return {};
  }
);

export const fetchFilterOptionsBySearchRM = createAsyncThunk(
  "fetch/filterOptionsBySearchRM",
  async (params) => {
    const response = await getFilterOptionsForSKUBySearch(params);
    if (response.success) {
      return response.data;
    }
    return {};
  }
);

export const fetchFilterOptionsBySearchPM = createAsyncThunk(
  "fetch/filterOptionsBySearchPM",
  async (params) => {
    const response = await getFilterOptionsForSKUBySearch(params);
    if (response.success) {
      return response.data;
    }
    return {};
  }
);
export const fetchFilterOptionsBySearchSPM = createAsyncThunk(
  "fetch/filterOptionsBySearchSPM",
  async (params) => {
    const response = await getFilterOptionsForSKUBySearch(params);
    if (response.success) {
      return response.data;
    }
    return {};
  }
);

export const fetchSourcingUnits = createAsyncThunk(
  "fetch/sourcingUnits",
  async (params) => {
    const response = await getSourcingUnits(params);
    if (response.success) {
      return response.data;
    }
    return {};
  }
);

export const simulationSlice = createSlice({
  name: "simulation",
  initialState,
  reducers: {
    updateSimulationData: (state, action) => {
      state.simulationData = action.payload;
    },
    resetSimulationData: (state) => {
      state.simulationData = { RM: [], PM: [], SPM: [] };
    },
    setHeaderVisibility: (state, action) => {
      state.showHeader = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchSimulationData.pending, (state) => {
      state.isSimulationLoading = true;
    });
    builder.addCase(fetchSimulationData.fulfilled, (state, action) => {
      state.isSimulationLoading = false;
      state.simulationData[action.payload.material] = action.payload;
    });
    builder.addCase(fetchSimulationData.rejected, (state, action) => {
      state.isSimulationLoading = true;
      state.error = action.error.message;
    });

    // fetch simluation data RM
    builder.addCase(fetchSimulationDataRM.pending, (state) => {
      state.isSimulationLoadingRM = true;
    });
    builder.addCase(fetchSimulationDataRM.fulfilled, (state, action) => {
      state.isSimulationLoadingRM = false;
      state.simulationData[action.payload.material] = action.payload;
    });
    builder.addCase(fetchSimulationDataRM.rejected, (state, action) => {
      state.isSimulationLoadingRM = true;
      state.error = action.error.message;
    });

    // fetch simluation data PM
    builder.addCase(fetchSimulationDataPM.pending, (state) => {
      state.isSimulationLoadingPM = true;
    });
    builder.addCase(fetchSimulationDataPM.fulfilled, (state, action) => {
      state.isSimulationLoadingPM = false;
      state.simulationData[action.payload.material] = action.payload;
    });
    builder.addCase(fetchSimulationDataPM.rejected, (state, action) => {
      state.isSimulationLoadingPM = true;
      state.error = action.error.message;
    });

    // fetch simluation data SPM
    builder.addCase(fetchSimulationDataSPM.pending, (state) => {
      state.isSimulationLoadingSPM = true;
    });
    builder.addCase(fetchSimulationDataSPM.fulfilled, (state, action) => {
      state.isSimulationLoadingSPM = false;
      state.simulationData[action.payload.material] = action.payload;
    });
    builder.addCase(fetchSimulationDataSPM.rejected, (state, action) => {
      state.isSimulationLoadingSPM = true;
      state.error = action.error.message;
    });

    //Get all add Item data for RM
    builder.addCase(fetchAddItemDataRM.pending, (state) => {
      state.isAddItemRMLoading = true;
    });
    builder.addCase(fetchAddItemDataRM.fulfilled, (state, action) => {
      state.isAddItemRMLoading = false;
      state.addItemDataRM = action.payload;
    });
    builder.addCase(fetchAddItemDataRM.rejected, (state, action) => {
      state.isAddItemRMLoading = true;
      state.error = action.error.message;
    });

    //Get all add Item data for PM
    builder.addCase(fetchAddItemDataPM.pending, (state) => {
      state.isAddItemPMLoading = true;
    });
    builder.addCase(fetchAddItemDataPM.fulfilled, (state, action) => {
      state.isAddItemPMLoading = false;
      state.addItemDataPM = action.payload;
    });
    builder.addCase(fetchAddItemDataPM.rejected, (state, action) => {
      state.isAddItemPMLoading = true;
      state.error = action.error.message;
    });

    //Get all add Item data for SPM
    builder.addCase(fetchAddItemDataSPM.pending, (state) => {
      state.isAddItemSPMLoading = true;
    });
    builder.addCase(fetchAddItemDataSPM.fulfilled, (state, action) => {
      state.isAddItemSPMLoading = false;
      state.addItemDataSPM = action.payload;
    });
    builder.addCase(fetchAddItemDataSPM.rejected, (state, action) => {
      state.isAddItemSPMLoading = true;
      state.error = action.error.message;
    });

    //Default filter SKUs
    builder.addCase(fetchFilterOptionsForSKU.pending, (state) => {
      state.isRMfilterOptionsLoading = true;
      state.isPMfilterOptionsLoading = true;
      state.isSPMfilterOptionsLoading = true;
    });
    builder.addCase(fetchFilterOptionsForSKU.fulfilled, (state, action) => {
      state.isRMfilterOptionsLoading = false;
      state.isPMfilterOptionsLoading = false;
      state.isSPMfilterOptionsLoading = false;
      state.rmFilterOptions = action.payload;
      state.pmFilterOptions = action.payload;
      state.spmFilterOptions = action.payload;
    });
    builder.addCase(fetchFilterOptionsForSKU.rejected, (state, action) => {
      state.isRMfilterOptionsLoading = true;
      state.isPMfilterOptionsLoading = true;
      state.isSPMfilterOptionsLoading = true;
      state.error = action.error.message;
    });

    //search filter RM SKUs options
    builder.addCase(fetchFilterOptionsBySearchRM.pending, (state) => {
      state.isRMfilterOptionsLoading = true;
    });
    builder.addCase(fetchFilterOptionsBySearchRM.fulfilled, (state, action) => {
      state.isRMfilterOptionsLoading = false;
      state.rmFilterOptions = action.payload;
    });
    builder.addCase(fetchFilterOptionsBySearchRM.rejected, (state, action) => {
      state.isRMfilterOptionsLoading = true;
      state.error = action.error.message;
    });

    //search filter PM SKUs options
    builder.addCase(fetchFilterOptionsBySearchPM.pending, (state) => {
      state.isPMfilterOptionsLoading = true;
    });
    builder.addCase(fetchFilterOptionsBySearchPM.fulfilled, (state, action) => {
      state.isPMfilterOptionsLoading = false;
      state.pmFilterOptions = action.payload;
    });
    builder.addCase(fetchFilterOptionsBySearchPM.rejected, (state, action) => {
      state.isPMfilterOptionsLoading = true;
      state.error = action.error.message;
    });

    //search filter SPM SKUs options
    builder.addCase(fetchFilterOptionsBySearchSPM.pending, (state) => {
      state.isSPMfilterOptionsLoading = true;
    });
    builder.addCase(
      fetchFilterOptionsBySearchSPM.fulfilled,
      (state, action) => {
        state.isSPMfilterOptionsLoading = false;
        state.spmFilterOptions = action.payload;
      }
    );
    builder.addCase(fetchFilterOptionsBySearchSPM.rejected, (state, action) => {
      state.isSPMfilterOptionsLoading = true;
      state.error = action.error.message;
    });

    //get sourcing units
    builder.addCase(fetchSourcingUnits.pending, (state) => {
      state.isSULoading = true;
    });
    builder.addCase(fetchSourcingUnits.fulfilled, (state, action) => {
      state.isSULoading = false;
      state.sourcingUnits = action.payload;
    });
    builder.addCase(fetchSourcingUnits.rejected, (state, action) => {
      state.isSULoading = true;
      state.error = action.error.message;
    });
  },
});

export const {
  updateSimulationData,
  resetSimulationData,
  setHeaderVisibility,
} = simulationSlice.actions;
export default simulationSlice.reducer;

/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Space, Table, theme } from "antd";
import React, { useEffect } from "react";
import { MdDeleteOutline, MdStarOutline } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { createSearchParams, useNavigate } from "react-router-dom";

import {
  addFavouriteSearch,
  deleteRecentSearch,
  fetchRecentSearchData,
} from "../../../redux/reducer/homeSlice";
import { getPathPrefix } from "../../../services/utilService";
import { notify } from "../../Common/Notification";

function RecentSearches() {
  const {
    token: { colorPrimary },
  } = theme.useToken();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { recentSearchData, isRecentDataLoading } = useSelector(
    (state) => state.home
  );

  async function fetchData() {
    await dispatch(fetchRecentSearchData());
  }

  useEffect(() => {
    fetchData();
  }, []);

  const handleDeleteRecent = async (record) => {
    const result = await dispatch(deleteRecentSearch({ sNo: record?.SNo }));
    if (result?.payload?.status === 1) {
      notify("success", "Deleted From Recent Searches!");

      fetchData();
    }
  };

  const handleAddFavourite = async (record) => {
    const result = await dispatch(addFavouriteSearch({ sNo: record?.SNo }));
    if (result?.payload === 1) {
      notify("success", "Added To Favourites!");

      fetchData();
    }
  };

  const handleSearch = (record) => {
    const searchParams = {
      product: record.SKUCode,
      productDesc: record.SKUDescription,
      brand: record.CorporateBrandCode || null,
      division: record.division || null,
      distributionCenter: record.PlantCode || null,
    };

    navigate({
      pathname: getPathPrefix("/compare/sourcing-units-list"),
      search: `?${createSearchParams(searchParams)}`,
    });
  };

  const columns = [
    {
      title: "SKU - (DU Code)",
      dataIndex: "SKUDescription",
      key: "SKUDescription",
      render: (_, record) => (
        <div
          style={{ color: colorPrimary, cursor: "pointer" }}
          onClick={() => handleSearch(record)}>
          {record?.SKUDescription}
        </div>
      ),
    },
    {
      title: "Dist. Plant",
      dataIndex: "PlantName-PlantCode",
      key: "PlantName-PlantCode",
      render: (_, record) => (
        <Space size="middle">
          {record.PlantName} - {record.PlantCode}
        </Space>
      ),
    },
    {
      title: "Created",
      dataIndex: "CreatedDate",
      key: "CreatedDate",
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <Button
            type="text"
            icon={
              <MdStarOutline
                size={20}
                color={colorPrimary}
                style={{ marginBottom: 4 }}
                onClick={() => handleAddFavourite(record)}
              />
            }
          />

          <Button
            type="text"
            icon={
              <MdDeleteOutline
                size={20}
                color={colorPrimary}
                style={{ marginBottom: 4 }}
                onClick={() => handleDeleteRecent(record)}
              />
            }
          />
        </Space>
      ),
    },
  ];

  return (
    <Table
      columns={columns}
      dataSource={recentSearchData}
      loading={isRecentDataLoading}
      pagination={false}
      size="small"
    />
  );
}

export default RecentSearches;

import { ReactSession } from "react-client-session";

import { encrypt, getHeaders } from "./utilService";

const BASE_URL = process.env.REACT_APP_BASE_URL;

export const getDefaultFilterOptionsForSKU = async () => {
  const apiResponse = await fetch(BASE_URL + "/default_api/v2", {
    headers: getHeaders(),
    body: JSON.stringify({
      randomCode: "d",
      userId: encrypt(ReactSession.get("email")),
    }),
    method: "POST",
  });

  if (!apiResponse.ok) {
    // logout
    if (apiResponse.status === 440) {
      sessionStorage.clear();
      window.location.reload();
    }

    return {
      success: false,
      data: null,
      error: {
        message: `An error has occured: ${apiResponse.status}`,
      },
    };
  }

  const data = await apiResponse.json();

  return {
    success: true,
    data: data,
    error: null,
  };
};

export const getFilterOptionsForSKU = async (params) => {
  const apiResponse = await fetch(BASE_URL + "/filter/v8", {
    headers: getHeaders(),
    body: JSON.stringify({
      brand: params.brand || null,
      category: params.category || null,
      centerRegion: params.centerRegion || null,
      country: params.country || null,
      division: params.division || null,
      form: params.form || null,
      product: params.product || null,
      subSector: params.subSector || null,
      subregion: params.subregion || null,
      userId: encrypt(ReactSession.get("email")),
    }),
    method: "POST",
  });

  if (!apiResponse.ok) {
    // logout
    if (apiResponse.status === 440) {
      sessionStorage.clear();
      window.location.reload();
    }

    return {
      success: false,
      data: null,
      error: {
        message: `An error has occured: ${apiResponse.status}`,
      },
    };
  }

  const data = await apiResponse.json();

  return {
    success: true,
    data: data,
    error: null,
  };
};

export const getFilterOptionsForSKUBySearch = async (params) => {
  const apiResponse = await fetch(BASE_URL + "/onsearch", {
    headers: getHeaders(),
    body: JSON.stringify({
      brand: "",
      category: "",
      centerRegion: "",
      country: "",
      division: "",
      form: "",
      sku: "",
      subSector: "",
      subregion: "",
      userId: encrypt(ReactSession.get("email")),
      ...params,
    }),
    method: "POST",
  });

  if (!apiResponse.ok) {
    // logout
    if (apiResponse.status === 440) {
      sessionStorage.clear();
      window.location.reload();
    }

    return {
      success: false,
      data: null,
      error: {
        message: `An error has occured: ${apiResponse.status}`,
      },
    };
  }

  const data = await apiResponse.json();

  return {
    success: true,
    data: data,
    error: null,
  };
};

export const getDefaultFilterOptionsForDC = async () => {
  const apiResponse = await fetch(BASE_URL + "/plant/default2", {
    headers: getHeaders(),
    body: JSON.stringify({
      randomCode: "d",
      userId: encrypt(ReactSession.get("email")),
    }),
    method: "POST",
  });

  if (!apiResponse.ok) {
    // logout
    if (apiResponse.status === 440) {
      sessionStorage.clear();
      window.location.reload();
    }

    return {
      success: false,
      data: null,
      error: {
        message: `An error has occured: ${apiResponse.status}`,
      },
    };
  }

  const data = await apiResponse.json();

  return {
    success: true,
    data: data,
    error: null,
  };
};

export const getFilterOptionsForDC = async (params) => {
  const apiResponse = await fetch(BASE_URL + "/plant/filterv1", {
    headers: getHeaders(),
    body: JSON.stringify({
      centerRegion: params.centerRegion || null,
      country: params.country || null,
      distributionCenter: params.distributionCenter || null,
      subregion: params.subregion || null,
      userId: encrypt(ReactSession.get("email")),
      ...params,
    }),
    method: "POST",
  });

  if (!apiResponse.ok) {
    // logout
    if (apiResponse.status === 440) {
      sessionStorage.clear();
      window.location.reload();
    }

    return {
      success: false,
      data: null,
      error: {
        message: `An error has occured: ${apiResponse.status}`,
      },
    };
  }

  const data = await apiResponse.json();

  return {
    success: true,
    data: data,
    error: null,
  };
};

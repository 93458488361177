import { Button, theme } from "antd";
import React from "react";

function Feedback() {
  const {
    token: { colorUnilever },
  } = theme.useToken();

  return (
    <div style={{ position: "fixed", right: -84, top: "60vh", zIndex: 1 }}>
      <Button
        type="primary"
        style={{
          transform: "rotate(270deg)",
          transformOrigin: "left",
          backgroundColor: colorUnilever,
          borderRadius: "4px 4px 0 0",
        }}>
        <a
          target="_blank"
          rel="noreferrer"
          href="https://forms.office.com/Pages/ResponsePage.aspx?id=Aq5v9jZdW0m_4Him_5-ObrtBcHBR17dIi7hYxxWx4WVUMDBXUFhONDNER1BQWUNCOUxaTFRCVDRHSSQlQCN0PWcu"
          style={{ textDecoration: "underline" }}>
          Feedback
        </a>
      </Button>
    </div>
  );
}

export default Feedback;

import { Button, Space, Typography } from "antd";
import React, { useState } from "react";
import { MdOutlineFileDownload } from "react-icons/md";
import { useSearchParams } from "react-router-dom";

import {
  getCapacityCSV,
  getMatchAverageCSV,
} from "../../../services/compareTableService";
import { downloadCSV } from "../../../services/utilService";
import { notify } from "../../Common/Notification";

const { Text } = Typography;

function DownloadCSV() {
  const hidden = window.location.pathname !== "/compare/sourcing-units-details";

  const [searchParams] = useSearchParams();

  const [isMALoading, setIsMALoading] = useState(false);
  const [isCapacityLoading, setIsCapacityLoading] = useState(false);

  const downloadMatchAverage = async () => {
    setIsMALoading(true);

    const params = {
      alternativePlantCode: searchParams.get("alternativePlantCode").split(","),
      brandCode: null,
      dcPlant: "None",
      distributionCenter: null,
      tarketPlantCode: [searchParams.get("tarketPlantCode")],
      tarketSKUCode: searchParams.get("tarketSKUCode"),
    };

    const response = await getMatchAverageCSV(params);

    if (response.success) {
      downloadCSV(response.data, "Match Average");
    } else {
      notify(
        "error",
        "Download Error",
        "Something went wrong while downloading Match Average"
      );
    }

    setIsMALoading(false);
  };

  const downloadCapacity = async () => {
    setIsCapacityLoading(true);

    const params = {
      alternativePlantCode: searchParams.get("alternativePlantCode").split(","),
      tarketPlantCode: searchParams.get("tarketPlantCode"),
    };

    const response = await getCapacityCSV(params);

    if (response.success) {
      downloadCSV(response.data, "Capacity");
    } else {
      notify(
        "error",
        "Download Error",
        "Something went wrong while downloading Capacity"
      );
    }

    setIsCapacityLoading(false);
  };

  return (
    <Space hidden={hidden}>
      <div style={{ width: 88 }}>
        <Text strong style={{ color: "#ffffff" }}>
          Download CSV
        </Text>
      </div>

      <Button
        style={{ width: 148 }}
        className="download-btn"
        type="default"
        icon={<MdOutlineFileDownload size={18} style={{ marginBottom: -4 }} />}
        loading={isMALoading}
        onClick={downloadMatchAverage}>
        Match Average
      </Button>

      <Button
        style={{ width: 112 }}
        className="download-btn"
        type="default"
        icon={<MdOutlineFileDownload size={18} style={{ marginBottom: -4 }} />}
        loading={isCapacityLoading}
        onClick={downloadCapacity}>
        Capacity
      </Button>
    </Space>
  );
}

export default DownloadCSV;

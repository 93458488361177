import { ReactSession } from "react-client-session";

import { encrypt, getHeaders, responseFormatter } from "./utilService";
const BASE_URL = process.env.REACT_APP_BASE_URL;

//Fetch the simulation data
export const getSimulationData = async (params) => {
  const apiResponse = await fetch(BASE_URL + "/simulationrmdata", {
    headers: getHeaders(),
    body: JSON.stringify({
      userId: encrypt(ReactSession.get("email")),
      ...params,
    }),
    method: "POST",
  });

  return responseFormatter(apiResponse);

  // return new Promise((resolve, reject) => {
  //   setTimeout(() => {
  //     resolve({
  //       success: true,
  //       data: stimulateData,
  //       error: null,
  //     });
  //   }, 2000);
  // });
};

//Fetch all add modal data
export const getAddItemsData = async (params) => {
  const apiResponse = await fetch(BASE_URL + "/additemsdefault", {
    headers: getHeaders(),
    body: JSON.stringify({
      ...params,
    }),
    method: "POST",
  });

  return responseFormatter(apiResponse);
};

export const getSourcingUnits = async (params) => {
  const apiResponse = await fetch(BASE_URL + "/findsimulatealternative", {
    headers: getHeaders(),
    body: JSON.stringify({
      userId: encrypt(ReactSession.get("email")),
      ...params,
    }),
    method: "POST",
  });

  return responseFormatter(apiResponse);
};

export const getPlantDetails = async (params) => {
  const apiResponse = await fetch(BASE_URL + "/simulationplantdetails", {
    headers: getHeaders(),
    body: JSON.stringify({
      userId: encrypt(ReactSession.get("email")),
      ...params,
    }),
    method: "POST",
  });

  return responseFormatter(apiResponse);
};

export const getSimulateCSV = async (params) => {
  const apiResponse = await fetch(BASE_URL + "/simulatedownload", {
    headers: getHeaders(),
    body: JSON.stringify({
      userId: encrypt(ReactSession.get("email")),
      ...params,
    }),
    method: "POST",
  });

  return responseFormatter(apiResponse);
};

import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { Col, Row, theme } from "antd";
import React, { useEffect } from "react";

import { appInsights, reactPlugin } from "../../../services/appInsights";
import ComparisionTable from "../ComparisionTable";
import Filters from "../Filters";
import Header from "./Header";

function SUList() {
  const {
    token: { colorUnilever },
  } = theme.useToken();

  useEffect(() => {
    appInsights.trackPageView({ name: "Compare page - Sourcing Units List" });
  }, []);

  return (
    <div>
      <Row>
        <Col span={24}>
          <div style={{ height: 24, backgroundColor: colorUnilever }}></div>
          <Header />
        </Col>
      </Row>
      <br />
      <Row gutter={[16, 16]} style={{ margin: "16px 28px 16px 16px" }}>
        <Col xs={24} sm={24} md={6} lg={3} xl={3} xxl={3}>
          <Filters />
        </Col>
        <Col xs={24} sm={24} md={18} lg={21} xl={21} xxl={21}>
          <ComparisionTable />
        </Col>
      </Row>
    </div>
  );
}

export default withAITracking(reactPlugin, SUList);

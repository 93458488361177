import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { Radio, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { appInsights, reactPlugin } from "../../../services/appInsights";
import FavouriteSearches from "./FavouriteSearches";
import RecentSearches from "./RecentSearches";

const { Title } = Typography;

function SearchHistory() {
  const [searchType, setSearchType] = useState("recent");
  const navigate = useNavigate();

  useEffect(() => {
    appInsights.trackPageView({ name: "Landing page" });
  }, []);

  const onSearchTypeChange = (e) => {
    setSearchType(e.target.value);
  };

  return (
    <div
      style={{
        width: "100%",
        backgroundColor: "#fff",
        padding: 16,
        marginTop: 24,
      }}>
      <Title level={5}>Search Options</Title>

      <div>
        <Radio.Group
          onChange={onSearchTypeChange}
          defaultValue={searchType}
          className="search-radio-group">
          <Radio.Button value="recent">Recent</Radio.Button>
          <Radio.Button value="fav">Favourites</Radio.Button>
          <Radio.Button
            value="new-search"
            onClick={() =>
              navigate({
                pathname: "new-search",
              })
            }>
            New Search
          </Radio.Button>
        </Radio.Group>
      </div>

      <div style={{ margin: "16px 0" }}>
        {searchType === "recent" ? <RecentSearches /> : <FavouriteSearches />}
      </div>
    </div>
  );
}

export default withAITracking(reactPlugin, SearchHistory);

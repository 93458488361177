import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { Col, Row, Spin, theme } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import { fetchComparisonDetails, fetchComparisonDetailsCost } from "../../../redux/reducer/compareTableSlice";
import { appInsights, reactPlugin } from "../../../services/appInsights";
import { isPilot } from "../../../services/utilService";
import Filters from "../Filters";
import Header from "./Header";
import SUDetailCard from "./SUDetailCard";
import SUDetailCardPilot from "./SUDetailCardPilot";

function SUDetails() {
  const {
    token: { colorUnilever },
  } = theme.useToken();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();

  const { isComparisonDataLoading, comparisonData, isComparisonCostDataLoading, comparisonCostData } = useSelector(
    (state) => state.compareTable
  );

  useEffect(() => {
    appInsights.trackPageView({
      name: "Compare page - Sourcing Units Details",
    });
  }, []);

  useEffect(() => {
    const params = {
      tarketSKUCode: searchParams.get("tarketSKUCode"),
      tarketPlantCode: [searchParams.get("tarketPlantCode")],
      alternativePlantCode: searchParams.get("alternativePlantCode").split(","),
      brandCode: searchParams.get("brandCode"),
      distributionCenter: null, // searchParams.get("distributionCenter")?.split(","),
      selectedDC: searchParams.get("selectedDC"),
      type: searchParams.get("type"),
      matchPer: Number(searchParams.get("matchPer")),
      dimension: Number(searchParams.get("dimension")),
      capacityRange: searchParams.get("capacityRange"),
      date: searchParams.get("date") || [],
      dcPlant: searchParams.get("dcPlant"),
    };

    dispatch(fetchComparisonDetails(params));
    dispatch(fetchComparisonDetailsCost(params));
  }, [dispatch, searchParams]);
  return (
    <div>
      <Row>
        <Col span={24}>
          <div style={{ height: 24, backgroundColor: colorUnilever }}></div>
          <Header />
        </Col>
      </Row>
      <br />
      <Row gutter={[16, 16]} style={{ margin: "16px 28px 16px 16px" }}>
        <Col xs={24} sm={24} md={6} lg={3} xl={3} xxl={3}>
          <Filters disabled />
        </Col>
        <Col xs={24} sm={24} md={18} lg={21} xl={21} xxl={21}>
          <Spin spinning={isComparisonDataLoading || isComparisonCostDataLoading}>
            <Row gutter={[16, 16]} style={{ minHeight: 498 }}>
              {comparisonData?.alternativeIntialData?.alternative?.map(
                (obj, index) => (
                  <Col
                    xs={24}
                    sm={12}
                    md={12}
                    lg={8}
                    xl={8}
                    xxl={8}
                    key={index}>
                    {isPilot() ? (
                      comparisonCostData[obj.plantCode.substring(1)] && <SUDetailCardPilot
                        data={obj}
                        target={
                          comparisonData?.alternativeIntialData?.targetPlant
                            ?.target1
                        }
                        comparisonCostData= {comparisonCostData[obj.plantCode.substring(1)]}
                        transporation= {comparisonCostData["transporation"][obj.plantCode.substring(1)]}
                        FinishedGoodKGWeight = {comparisonCostData["conversionRate"]}
                        transportationFlag = {comparisonCostData["transportationFlag"]}
                      />
                    ) : (
                      <SUDetailCard
                        data={obj}
                        target={
                          comparisonData?.alternativeIntialData?.targetPlant
                            ?.target1
                        }
                      />
                    )}
                  </Col>
                )
              )}
            </Row>
          </Spin>
        </Col>
      </Row>
    </div>
  );
}

export default withAITracking(reactPlugin, SUDetails);

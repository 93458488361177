import { PublicClientApplication } from "@azure/msal-browser";
import {
  AuthenticatedTemplate,
  MsalProvider,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import { ConfigProvider } from "antd";
import "antd/dist/reset.css";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";

import { msalConfig } from "../src/sso/authConfig";
import "./App.css";
import AppRoutes from "./AppRoutes";
import Authentication from "./components/Authentication";
import { store } from "./redux/config/store";
import theme from "./styles/theme";

const msalInstance = new PublicClientApplication(msalConfig);

function App() {
  return (
    <Provider store={store}>
      <ConfigProvider theme={theme}>
        <BrowserRouter>
          <MsalProvider instance={msalInstance}>
            <AuthenticatedTemplate>
              <AppRoutes />
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
              <Authentication />
            </UnauthenticatedTemplate>
          </MsalProvider>
        </BrowserRouter>
      </ConfigProvider>
    </Provider>
  );
}

export default App;

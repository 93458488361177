import { ReactSession } from "react-client-session";

import { encrypt, getHeaders, responseFormatter } from "./utilService";

const BASE_URL = process.env.REACT_APP_BASE_URL;

//Fetch the compare table data
export const getComapareList = async (params) => {
  const apiResponse = await fetch(BASE_URL + "/alternative/v4", {
    headers: getHeaders(),
    body: JSON.stringify({
      userId: encrypt(ReactSession.get("email")),
      ...params,
    }),
    method: "POST",
  });

  return responseFormatter(apiResponse);
};

//search the refineFilter data
export const getRefineFilterData = async (params) => {
  const apiResponse = await fetch(BASE_URL + "/refinefilter", {
    headers: getHeaders(),
    body: JSON.stringify({
      userId: encrypt(ReactSession.get("email")),
      ...params,
    }),
    method: "POST",
  });

  return responseFormatter(apiResponse);
};

export const getComparisonDetails = async (params) => {
  const apiResponse = await fetch(BASE_URL + "/alternative/detailsV6", {
    headers: getHeaders(),
    body: JSON.stringify({
      userId: encrypt(ReactSession.get("email")),
      ...params,
    }),
    method: "POST",
  });

  return responseFormatter(apiResponse);
};
export const getComparisonDetailsCost = async (params) => {
  const apiResponse = await fetch(BASE_URL + "/alternative/detailsCost", {
    headers: getHeaders(),
    body: JSON.stringify({
      userId: encrypt(ReactSession.get("email")),
      ...params,
    }),
    method: "POST",
  });

  return responseFormatter(apiResponse);
};

export const getMatchAverageCSV = async (params) => {
  const apiResponse = await fetch(BASE_URL + "/downloadcsvV1", {
    headers: getHeaders(),
    body: JSON.stringify({
      userId: encrypt(ReactSession.get("email")),
      ...params,
    }),
    method: "POST",
  });

  return responseFormatter(apiResponse);
};

export const getCapacityCSV = async (params) => {
  const apiResponse = await fetch(BASE_URL + "/capacitydownloadcsv", {
    headers: getHeaders(),
    body: JSON.stringify({
      userId: encrypt(ReactSession.get("email")),
      ...params,
    }),
    method: "POST",
  });

  return responseFormatter(apiResponse);
};

export const getSKUDetails = async (params) => {
  const apiResponse = await fetch(
    BASE_URL + "/alternative/details/plantlevel/V3",
    {
      headers: getHeaders(),
      body: JSON.stringify({
        userId: encrypt(ReactSession.get("email")),
        ...params,
      }),
      method: "POST",
    }
  );

  return responseFormatter(apiResponse);
};

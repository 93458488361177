import { useMsal } from "@azure/msal-react";
import { Layout } from "antd";
import React, { useEffect } from "react";
import { ReactSession } from "react-client-session";
import { Outlet } from "react-router-dom";

import { appInsights } from "../../services/appInsights";
import AppHeader from "./AppHeader";
import Feedback from "./Feedback";

const { Content } = Layout;

function AppLayout() {
  const { accounts } = useMsal();

  useEffect(() => {
    ReactSession.setStoreType("localStorage");
    ReactSession.set("name", accounts?.[0]?.name);
    ReactSession.set("email", accounts?.[0]?.username);
    ReactSession.set("role", accounts?.[0]?.idTokenClaims.roles[0]);

    appInsights.setAuthenticatedUserContext(accounts?.[0]?.username);
  }, [accounts]);

  return (
    <Layout style={{ overflow: "auto", height: "100vh" }}>
      <Feedback />

      <AppHeader></AppHeader>

      <Content>
        <Outlet />
      </Content>
    </Layout>
  );
}

export default AppLayout;

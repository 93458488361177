import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  getDashboardData,
  getRecentSearchData,
  getFavSearchData,
  deleteFavSearch,
  addFavSearch,
  deleteRecntSearch,
} from "../../services/homeService";

const initialState = {
  isDashboardDataLoading: false,
  isRecentDataLoading: false,
  isFavDataLoading: false,
  dashboardData: {
    Countries: 0,
    DistributionCenter: 0,
    Factories: 0,
    Products: 0,
  },
  recentSearchData: [],
  recentfavData: [],
};

export const fetchDashboardData = createAsyncThunk(
  "fetch/dashboardData",
  async () => {
    const response = await getDashboardData();
    if (response.success) {
      return response.data;
    }
    return {};
  }
);

export const fetchRecentSearchData = createAsyncThunk(
  "fetch/recentSearchData",
  async () => {
    const response = await getRecentSearchData();
    if (response.success) {
      return response.data;
    }
    return {};
  }
);

export const fetchFavSearchData = createAsyncThunk(
  "fetch/favSearchData",
  async (body) => {
    const response = await getFavSearchData(body);
    if (response.success) {
      return response.data;
    }
    return {};
  }
);

export const addFavouriteSearch = createAsyncThunk(
  "add/favouriteSearch",
  async (body) => {
    const response = await addFavSearch(body);
    if (response.success) {
      return response.data;
    }
    return {};
  }
);

export const deleteFavouriteSearch = createAsyncThunk(
  "delete/favouriteSearch",
  async (body) => {
    const response = await deleteFavSearch(body);
    if (response.success) {
      return response.data;
    }
    return {};
  }
);

export const deleteRecentSearch = createAsyncThunk(
  "delete/recentSearch",
  async (body) => {
    const response = await deleteRecntSearch(body);
    if (response.success) {
      return response.data;
    }
    return {};
  }
);

export const homeSlice = createSlice({
  name: "home",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchDashboardData.pending, (state) => {
      state.isDashboardDataLoading = true;
    });
    builder.addCase(fetchDashboardData.fulfilled, (state, action) => {
      state.isDashboardDataLoading = false;
      state.dashboardData = action.payload;
    });
    builder.addCase(fetchDashboardData.rejected, (state, action) => {
      state.isDashboardDataLoading = false;
      state.error = action.error.message;
    });

    //Recent search builders
    builder.addCase(fetchRecentSearchData.pending, (state) => {
      state.isRecentDataLoading = true;
    });
    builder.addCase(fetchRecentSearchData.fulfilled, (state, action) => {
      state.isRecentDataLoading = false;
      state.recentSearchData = action.payload;
    });
    builder.addCase(fetchRecentSearchData.rejected, (state, action) => {
      state.isRecentDataLoading = false;
      state.error = action.error.message;
    });

    //Favourites search builders
    builder.addCase(fetchFavSearchData.pending, (state) => {
      state.isFavDataLoading = true;
    });
    builder.addCase(fetchFavSearchData.fulfilled, (state, action) => {
      state.isFavDataLoading = false;
      state.recentfavData = action.payload;
    });
    builder.addCase(fetchFavSearchData.rejected, (state, action) => {
      state.isFavDataLoading = false;
      state.error = action.error.message;
    });

    //ADD Favourites search builders
    builder.addCase(addFavouriteSearch.pending, (state) => {
      state.isRecentDataLoading = true;
    });
    builder.addCase(addFavouriteSearch.fulfilled, (state, action) => {
      state.isRecentDataLoading = false;
    });
    builder.addCase(addFavouriteSearch.rejected, (state, action) => {
      state.isRecentDataLoading = true;
    });

    //Delete Favourites search builders
    builder.addCase(deleteFavouriteSearch.pending, (state) => {
      state.isFavDataLoading = true;
    });
    builder.addCase(deleteFavouriteSearch.fulfilled, (state, action) => {
      state.isFavDataLoading = false;
    });
    builder.addCase(deleteFavouriteSearch.rejected, (state, action) => {
      state.isFavDataLoading = true;
    });

    //Delete Recent search builders
    builder.addCase(deleteRecentSearch.pending, (state) => {
      state.isRecentDataLoading = true;
    });
    builder.addCase(deleteRecentSearch.fulfilled, (state, action) => {
      state.isRecentDataLoading = false;
    });
    builder.addCase(deleteRecentSearch.rejected, (state, action) => {
      state.isRecentDataLoading = true;
    });
  },
});

export default homeSlice.reducer;

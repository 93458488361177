import { useMsal } from "@azure/msal-react";
import { Button, Card, Typography, theme } from "antd";
import React from "react";

import loginBG from "../../assets/img/loginBack.png";
import RTVA from "../../assets/img/logo_RTVA_color.svg";
import UnileverLogo from "../../assets/img/logo_unilever_color.svg";
import { loginRequest } from "../../sso/authConfig";

const { Title, Text } = Typography;

function Authentication() {
  const { instance, inProgress } = useMsal();
  const {
    token: { colorUnilever },
  } = theme.useToken();

  function handleLogin(instance) {
    instance.loginPopup(loginRequest).catch((e) => {
      console.error(e);
    });
  }

  return (
    <div
      style={{
        height: "100vh",
        background: `url(${loginBG})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}>
      <Card bordered={false}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-evenly",
          }}>
          <div
            style={{ display: "flex", alignItems: "end", padding: "20px 0" }}>
            <img src={UnileverLogo} alt="Unilever" height={72} />
            <img src={RTVA} alt="RTVA" style={{ marginBottom: 1 }} />
          </div>

          <div
            style={{
              padding: "28px 0",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}>
            <Text style={{ color: colorUnilever, fontSize: 18 }}>
              Welcome to
            </Text>
            <Title level={2} style={{ marginTop: 0, color: colorUnilever }}>
              Sourcing Identification
            </Title>
          </div>

          <div style={{ padding: "28px 0" }}>
            <Button
              type="primary"
              style={{ backgroundColor: colorUnilever }}
              onClick={() => handleLogin(instance)}
              loading={inProgress === "login"}>
              Sign in with Unilever ID
            </Button>
          </div>
        </div>
      </Card>
    </div>
  );
}

export default Authentication;

import { ReactSession } from "react-client-session";

import { encrypt, getHeaders, responseFormatter } from "./utilService";

const BASE_URL = process.env.REACT_APP_BASE_URL;

export const getDashboardData = async () => {
  const apiResponse = await fetch(BASE_URL + "/high_level_data", {
    headers: getHeaders(),
    body: JSON.stringify({
      userId: encrypt(ReactSession.get("email")),
    }),
    method: "POST",
  });

  return responseFormatter(apiResponse);
};

//Fetch the Recent searches data
export const getRecentSearchData = async () => {
  const apiResponse = await fetch(BASE_URL + "/recent_search", {
    headers: getHeaders(),
    body: JSON.stringify({
      userId: encrypt(ReactSession.get("email")),
    }),
    method: "POST",
  });

  return responseFormatter(apiResponse);
};

//Fetch the Favorites searches data
export const getFavSearchData = async (params) => {
  const apiResponse = await fetch(BASE_URL + "/favorite/read", {
    headers: getHeaders(),
    body: JSON.stringify({
      ...params,
      userId: encrypt(ReactSession.get("email")),
    }),
    method: "POST",
  });

  return responseFormatter(apiResponse);
};

//Add the Favorite searches
export const addFavSearch = async (params) => {
  const apiResponse = await fetch(BASE_URL + "/favorite/write", {
    headers: getHeaders(),
    body: JSON.stringify({
      ...params,
      userId: encrypt(ReactSession.get("email")),
    }),
    method: "POST",
  });

  return responseFormatter(apiResponse);
};

//Delete the Favorite searches
export const deleteFavSearch = async (params) => {
  const apiResponse = await fetch(BASE_URL + "/favorite/delete", {
    headers: getHeaders(),
    body: JSON.stringify({
      ...params,
      userId: encrypt(ReactSession.get("email")),
    }),
    method: "POST",
  });

  return responseFormatter(apiResponse);
};

//Delete the recent searches
export const deleteRecntSearch = async (params) => {
  const apiResponse = await fetch(BASE_URL + "/recent_search_delete", {
    headers: getHeaders(),
    body: JSON.stringify({
      ...params,
      userId: encrypt(ReactSession.get("email")),
    }),
    method: "POST",
  });

  return responseFormatter(apiResponse);
};

//Delete the recent searches
export const checkUserInfo = async () => {
  const apiResponse = await fetch(BASE_URL + "/check/userinfo", {
    headers: getHeaders(),
    body: JSON.stringify({
      randomCode: "d",
      userId: encrypt(ReactSession.get("email")),
    }),
    method: "POST",
  });

  return responseFormatter(apiResponse);
};

/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Space, Table, theme } from "antd";
import React, { useEffect } from "react";
import { MdDeleteOutline } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { createSearchParams, useNavigate } from "react-router-dom";

import {
  deleteFavouriteSearch,
  fetchFavSearchData,
} from "../../../redux/reducer/homeSlice";
import { getPathPrefix } from "../../../services/utilService";
import { notify } from "../../Common/Notification";

function FavouriteSearches() {
  const {
    token: { colorPrimary },
  } = theme.useToken();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { recentfavData, isFavDataLoading } = useSelector(
    (state) => state.home
  );

  async function fetchData(dataCount) {
    dispatch(fetchFavSearchData({ dataCount }));
  }

  useEffect(() => {
    fetchData(0);
  }, []);

  const handleDeleteFav = async (record) => {
    const result = await dispatch(deleteFavouriteSearch({ sNo: record?.SNo }));
    if (result?.payload?.status === 1) {
      notify("success", "Deleted From Favourites!");
      fetchData(0);
    }
  };

  const handleSearch = (record) => {
    const searchParams = {
      product: record.SKUCode,
      productDesc: record.SKUDescription,
      brand: record.CorporateBrandCode || null,
      division: record.division || null,
      distributionCenter: record.PlantCode || null,
    };

    navigate({
      pathname: getPathPrefix("/compare/sourcing-units-list"),
      search: `?${createSearchParams(searchParams)}`,
    });
  };

  const columns = [
    {
      title: "SKU - (DU Code)",
      dataIndex: "SKUDescription",
      key: "SKUDescription",
      render: (_, record) => (
        <div
          style={{ color: colorPrimary, cursor: "pointer" }}
          onClick={() => handleSearch(record)}>
          {record?.SKUDescription}
        </div>
      ),
    },
    {
      title: "Dist. Plant",
      dataIndex: "PlantName-PlantCode",
      key: "PlantName-PlantCode",
      render: (_, record) => (
        <Space size="middle">
          {record.PlantName} - {record.PlantCode}
        </Space>
      ),
    },
    {
      title: "Created",
      dataIndex: "CreatedDate",
      key: "CreatedDate",
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <Button
            type="text"
            icon={
              <MdDeleteOutline
                size={20}
                color={colorPrimary}
                style={{ marginBottom: 4 }}
                onClick={() => handleDeleteFav(record)}
              />
            }
          />
        </Space>
      ),
    },
  ];

  return (
    <Table
      columns={columns}
      dataSource={recentfavData?.[0]}
      loading={isFavDataLoading}
      size="small"
      pagination={{
        defaultCurrent: 1,
        total: recentfavData?.[1]?.dataCount || 0,
        pageSize: 5,
        size: "small",
        onChange: (page, pageSize) => fetchData((page - 1) * pageSize),
      }}
    />
  );
}

export default FavouriteSearches;

import { withAITracking } from "@microsoft/applicationinsights-react-js";
import {
  Button,
  Col,
  Divider,
  Form,
  Row,
  Select,
  Space,
  Spin,
  Typography,
  theme,
} from "antd";
import { debounce } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { PiCaretDownLight } from "react-icons/pi";
import { useDispatch, useSelector } from "react-redux";
import { createSearchParams, useNavigate } from "react-router-dom";

import {
  fetchDefaultFilterOptionsForDC,
  fetchDefaultFilterOptionsForSKU,
  fetchFilterOptionsForDC,
  fetchFilterOptionsForSKU,
  fetchFilterOptionsForSKUBySearch,
} from "../../../redux/reducer/searchSlice";
import { appInsights, reactPlugin } from "../../../services/appInsights";
import { getPathPrefix } from "../../../services/utilService";

const { Title, Text } = Typography;
const debounceTimeout = 500;

function MiniLoader() {
  return (
    <Space
      style={{
        height: "40px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
      align="center">
      <Spin size="small" />
    </Space>
  );
}

function NewSearch() {
  const {
    token: { colorPrimary },
  } = theme.useToken();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [skuForm] = Form.useForm();
  const [dcForm] = Form.useForm();

  const {
    isSKUFiltersLoading,
    isSKUFilterOptionsLoading,
    isDCFiltersLoading,
    skuFilterOptions,
    dcFilterOptions,
  } = useSelector((state) => state.search);

  const [showAdditionalSKUFilters, setShowAdditionalSKUFilters] =
    useState(false);
  const [showAdditionalDCFilters, setShowAdditionalDCFilters] = useState(false);

  useEffect(() => {
    appInsights.trackPageView({ name: "New Search page" });

    dispatch(fetchDefaultFilterOptionsForSKU());
    dispatch(fetchDefaultFilterOptionsForDC());
  }, [dispatch]);

  useEffect(() => {
    const skuFormValues = {
      brand:
        skuFilterOptions.Brand.length === 1
          ? Object.keys(skuFilterOptions.Brand[0])[0]
          : null,
      category:
        skuFilterOptions.Category.length === 1
          ? Object.keys(skuFilterOptions.Category[0])[0]
          : null,
      centerRegion:
        skuFilterOptions.CenterRegion.length === 1
          ? Object.keys(skuFilterOptions.CenterRegion[0])[0]
          : null,
      country:
        skuFilterOptions.Country.length === 1
          ? Object.keys(skuFilterOptions.Country[0])[0]
          : null,
      division:
        skuFilterOptions.Division.length === 1
          ? Object.keys(skuFilterOptions.Division[0])[0]
          : null,
      form:
      skuFilterOptions?.Form_Selected?.length > 0 
      ? skuFilterOptions.Form_Selected.map(ss => Object.keys(ss)[0])[0]
      : null,
      product:
        skuFilterOptions.Product.length === 1
          ? Object.keys(skuFilterOptions.Product[0])[0]
          : null,
      subSector:
      skuFilterOptions?.Subsector_Selected?.length > 0 
      ? skuFilterOptions.Subsector_Selected.map(ss => Object.keys(ss)[0])[0]
      : null,
      subregion:
        skuFilterOptions.SubRegion.length === 1
          ? Object.keys(skuFilterOptions.SubRegion[0])[0]
          : null,
    };
    skuForm.setFieldsValue(skuFormValues);
  }, [skuFilterOptions, skuForm]);

  useEffect(() => {
    const dcFormValues = {
      centerRegion:
        dcFilterOptions.centerRegion.length === 1
          ? Object.keys(dcFilterOptions.centerRegion[0])[0]
          : null,
      subregion:
        dcFilterOptions.subregion.length === 1
          ? Object.keys(dcFilterOptions.subregion[0])[0]
          : null,
      country:
        dcFilterOptions.country.length === 1
          ? Object.keys(dcFilterOptions.country[0])[0]
          : null,
      distributionCenter:
        dcFilterOptions.distributionCenter.length === 1
          ? Object.keys(dcFilterOptions.distributionCenter[0])[0]
          : null,
    };
    dcForm.setFieldsValue(dcFormValues);
  }, [dcFilterOptions, dcForm]);

  const onSKUFilterFormValuesChange = (changedValues, values) => {
    const subSector = values.subSector !== null?[values.subSector]:[]
    const form = values.form !== null?[values.form]:[]
    dispatch(fetchFilterOptionsForSKU({...values, subSector, form}));
    console.log({...values, subSector, form})
  };

  const onDCFilterFormValuesChange = (changedValues, values) => {
    dispatch(fetchFilterOptionsForDC(values));
  };

  const handleSubmit = () => {
    if (
      skuForm.getFieldsError().filter((o) => o.errors.length > 0).length === 0
    ) {
      const skuFormValues = skuForm.getFieldsValue();
      const dcFormValues = dcForm.getFieldsValue();

      const searchParams = {
        product: skuFormValues.product,
        productDesc: skuFilterOptions.Product.find(
          (option) => option[skuFormValues.product]
        )[skuFormValues.product],
        brand: skuFormValues.brand || null,
        division: skuFormValues.division || null,
        distributionCenter: dcFormValues.distributionCenter,
      };

      navigate({
        pathname: getPathPrefix("/compare/sourcing-units-list"),
        search: `?${createSearchParams(searchParams)}`,
      });
    }
  };

  const filterOptions = (input, option) =>
    (option?.label?.toLowerCase() ?? "").includes(input.toLowerCase());

  const debouncedSearch = useRef(
    debounce(async (searchKey, filter) => {
      await dispatch(
        fetchFilterOptionsForSKUBySearch({
          searchKey,
          ...filter,
        })
      );
    }, debounceTimeout)
  ).current;

  const handleOnSearch = (searchKey, filter) => {
    debouncedSearch(searchKey, filter);
  };

  const handleReset = () => {
    skuForm.resetFields();
    dcForm.resetFields();
    dispatch(fetchDefaultFilterOptionsForSKU());
    dispatch(fetchDefaultFilterOptionsForDC());
  };

  return (
    <div
      style={{
        width: "100%",
        backgroundColor: "#fff",
        padding: 16,
        marginTop: 24,
      }}>
      <Row>
        <Col span={24}>
          <Title
            level={4}
            style={{
              margin: 0,
            }}>
            Search & Compare Alternate Plants
          </Title>
          <Divider
            style={{
              margin: "16px 0",
            }}
          />
        </Col>
      </Row>

      <Spin spinning={isSKUFiltersLoading || isDCFiltersLoading}>
        <div>
          <Form
            name="sku_search_filters"
            form={skuForm}
            layout="vertical"
            size="small"
            autoComplete="off"
            requiredMark
            initialValues={{
              brand: null,
              category: null,
              centerRegion: null,
              country: null,
              division: null,
              form: null,
              product: null,
              subSector: null,
              subregion: null,
            }}
            onValuesChange={onSKUFilterFormValuesChange}>
            <div>
              <div>
                <Text
                  strong
                  style={{
                    color: "#7D7D7D",
                  }}>
                  Enter a specific SKU (DU Code) or the description for the item
                  to compare and get ex-works cost
                </Text>

                <Row gutter={[8, 0]} align="middle">
                  <Col xs={24} sm={12} md={8} lg={6} xl={6} xxl={6}>
                    <Form.Item
                      name={`product`}
                      label={`SKU (DU Code) or the Description`}
                      rules={[{ required: true }]}>
                      <Select
                        suffixIcon={
                          <PiCaretDownLight size={12} color="#292929" />
                        }
                        placeholder="Select"
                        allowClear
                        showSearch
                        filterOption={false}
                        onSearch={(value) =>
                          handleOnSearch("product", { sku: value })
                        }
                        dropdownRender={(menu) => (
                          <>
                            {isSKUFilterOptionsLoading ? <MiniLoader /> : menu}
                          </>
                        )}>
                        {skuFilterOptions.Product.map((option) => {
                          const key = Object.keys(option)[0];
                          const label = Object.values(option)[0];

                          return (
                            <Select.Option key={key} value={key} label={label}>
                              {label}
                            </Select.Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col
                    xs={24}
                    sm={12}
                    md={8}
                    lg={6}
                    xl={6}
                    xxl={6}
                    hidden={showAdditionalSKUFilters}
                    aria-hidden={showAdditionalSKUFilters}>
                    <Text>
                      Don't know the DU code?{" "}
                      <span
                        style={{
                          color: colorPrimary,
                          cursor: "pointer",
                        }}
                        onClick={() => setShowAdditionalSKUFilters(true)}>
                        Optimize List
                      </span>
                    </Text>
                  </Col>
                </Row>
              </div>
              <div
                hidden={!showAdditionalSKUFilters}
                aria-hidden={!showAdditionalSKUFilters}>
                <Text
                  strong
                  style={{
                    color: "#7D7D7D",
                  }}>
                  Use the filters below to create a short list of DUs to select
                  in the field above.
                </Text>

                <Row align="bottom" gutter={[8, 8]}>
                  <Col xs={12} sm={8} md={6} lg={3} xl={3} xxl={3}>
                    <Form.Item name={`division`} label={`Business Group`}>
                      <Select
                        suffixIcon={
                          <PiCaretDownLight size={12} color="#292929" />
                        }
                        placeholder="Select"
                        allowClear
                        showSearch
                        filterOption={false}
                        onSearch={(value) =>
                          handleOnSearch("division", { division: value })
                        }
                        dropdownRender={(menu) => (
                          <>
                            {isSKUFilterOptionsLoading ? <MiniLoader /> : menu}
                          </>
                        )}>
                        {skuFilterOptions.Division.map((option) => {
                          const key = Object.keys(option)[0];
                          const label = Object.values(option)[0];

                          return (
                            <Select.Option key={key} value={key} label={label}>
                              {label}
                            </Select.Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col xs={12} sm={8} md={6} lg={3} xl={3} xxl={3}>
                    <Form.Item name={`category`} label={`Category`}>
                      <Select
                        suffixIcon={
                          <PiCaretDownLight size={12} color="#292929" />
                        }
                        placeholder="Select"
                        allowClear
                        showSearch
                        filterOption={false}
                        onSearch={(value) =>
                          handleOnSearch("category", { category: value })
                        }
                        dropdownRender={(menu) => (
                          <>
                            {isSKUFilterOptionsLoading ? <MiniLoader /> : menu}
                          </>
                        )}>
                        {skuFilterOptions.Category.map((option) => {
                          const key = Object.keys(option)[0];
                          const label = Object.values(option)[0];

                          return (
                            <Select.Option key={key} value={key} label={label}>
                              {label}
                            </Select.Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col xs={12} sm={8} md={6} lg={3} xl={3} xxl={3}>
                    <Form.Item name={`subSector`} label={`Sub-Sector`}>
                      <Select
                        suffixIcon={
                          <PiCaretDownLight size={12} color="#292929" />
                        }
                        placeholder="Select"
                        allowClear
                        showSearch
                        filterOption={false}
                        onSearch={(value) =>
                          handleOnSearch("subSector", { subSector: value })
                        }
                        dropdownRender={(menu) => (
                          <>
                            {isSKUFilterOptionsLoading ? <MiniLoader /> : menu}
                          </>
                        )}>
                        {skuFilterOptions.Subsector.map((option) => {
                          const key = Object.keys(option)[0];
                          const label = Object.values(option)[0];

                          return (
                            <Select.Option key={key} value={key} label={label}>
                              {label}
                            </Select.Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col xs={12} sm={8} md={6} lg={3} xl={3} xxl={3}>
                    <Form.Item name={`form`} label={`Product Form Name`}>
                      <Select
                        suffixIcon={
                          <PiCaretDownLight size={12} color="#292929" />
                        }
                        placeholder="Select"
                        allowClear
                        showSearch
                        filterOption={false}
                        onSearch={(value) =>
                          handleOnSearch("form", { form: value })
                        }
                        dropdownRender={(menu) => (
                          <>
                            {isSKUFilterOptionsLoading ? <MiniLoader /> : menu}
                          </>
                        )}>
                        {skuFilterOptions.Form.map((option) => {
                          const key = Object.keys(option)[0];
                          const label = Object.values(option)[0];

                          return (
                            <Select.Option key={key} value={key} label={label}>
                              {label}
                            </Select.Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col xs={12} sm={8} md={6} lg={3} xl={3} xxl={3}>
                    <Form.Item name={`brand`} label={`Brand`}>
                      <Select
                        suffixIcon={
                          <PiCaretDownLight size={12} color="#292929" />
                        }
                        placeholder="Select"
                        allowClear
                        showSearch
                        filterOption={false}
                        onSearch={(value) =>
                          handleOnSearch("brand", { brand: value })
                        }
                        dropdownRender={(menu) => (
                          <>
                            {isSKUFilterOptionsLoading ? <MiniLoader /> : menu}
                          </>
                        )}>
                        {skuFilterOptions.Brand.map((option) => {
                          const key = Object.keys(option)[0];
                          const label = Object.values(option)[0];

                          return (
                            <Select.Option key={key} value={key} label={label}>
                              {label}
                            </Select.Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col xs={12} sm={8} md={6} lg={3} xl={3} xxl={3}>
                    <Form.Item
                      name={`centerRegion`}
                      label={`Sourcing Unit Region`}>
                      <Select
                        suffixIcon={
                          <PiCaretDownLight size={12} color="#292929" />
                        }
                        placeholder="Select"
                        allowClear
                        showSearch
                        filterOption={false}
                        onSearch={(value) =>
                          handleOnSearch("centerRegion", {
                            centerRegion: value,
                          })
                        }
                        dropdownRender={(menu) => (
                          <>
                            {isSKUFilterOptionsLoading ? <MiniLoader /> : menu}
                          </>
                        )}>
                        {skuFilterOptions.CenterRegion.map((option) => {
                          const key = Object.keys(option)[0];
                          const label = Object.values(option)[0];

                          return (
                            <Select.Option key={key} value={key} label={label}>
                              {label}
                            </Select.Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col xs={12} sm={8} md={6} lg={3} xl={3} xxl={3}>
                    <Form.Item
                      name={`subregion`}
                      label={`Sourcing Unit Sub Region`}>
                      <Select
                        suffixIcon={
                          <PiCaretDownLight size={12} color="#292929" />
                        }
                        placeholder="Select"
                        allowClear
                        showSearch
                        filterOption={false}
                        onSearch={(value) =>
                          handleOnSearch("subregion", { subregion: value })
                        }
                        dropdownRender={(menu) => (
                          <>
                            {isSKUFilterOptionsLoading ? <MiniLoader /> : menu}
                          </>
                        )}>
                        {skuFilterOptions.SubRegion.map((option) => {
                          const key = Object.keys(option)[0];
                          const label = Object.values(option)[0];

                          return (
                            <Select.Option key={key} value={key} label={label}>
                              {label}
                            </Select.Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col xs={12} sm={8} md={6} lg={3} xl={3} xxl={3}>
                    <Form.Item name={`country`} label={`Sourcing Unit Country`}>
                      <Select
                        suffixIcon={
                          <PiCaretDownLight size={12} color="#292929" />
                        }
                        placeholder="Select"
                        allowClear
                        showSearch
                        filterOption={false}
                        onSearch={(value) =>
                          handleOnSearch("country", { country: value })
                        }
                        dropdownRender={(menu) => (
                          <>
                            {isSKUFilterOptionsLoading ? <MiniLoader /> : menu}
                          </>
                        )}>
                        {skuFilterOptions.Country.map((option) => {
                          const key = Object.keys(option)[0];
                          const label = Object.values(option)[0];

                          return (
                            <Select.Option key={key} value={key} label={label}>
                              {label}
                            </Select.Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
              </div>
            </div>
          </Form>
          <Form
            name="dc_search_filters"
            form={dcForm}
            layout="vertical"
            size="small"
            autoComplete="off"
            initialValues={{
              centerRegion: null,
              subregion: null,
              country: null,
              distributionCenter: null,
            }}
            onValuesChange={onDCFilterFormValuesChange}>
            <div
              style={{
                margin: "16px 0",
              }}>
              <Text
                strong
                style={{
                  color: "#7D7D7D",
                }}>
                OPTIONAL: Select distribution center to get full Transfer Price
                (TP)
              </Text>

              <Row gutter={[8, 8]} align="middle">
                <Col xs={24} sm={12} md={6} lg={6} xl={6} xxl={6}>
                  <Form.Item
                    name={`centerRegion`}
                    label={`Distribution Center Region`}>
                    <Select
                      suffixIcon={
                        <PiCaretDownLight size={12} color="#292929" />
                      }
                      placeholder="Select"
                      allowClear
                      showSearch
                      optionFilterProp="children"
                      filterOption={filterOptions}>
                      {dcFilterOptions.centerRegion.map((option) => {
                        const key = Object.keys(option)[0];
                        const label = Object.values(option)[0];

                        return (
                          <Select.Option key={key} value={key} label={label}>
                            {label}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Col>

                <Col
                  xs={24}
                  sm={12}
                  md={6}
                  lg={6}
                  xl={6}
                  xxl={6}
                  hidden={showAdditionalDCFilters}
                  aria-hidden={showAdditionalDCFilters}>
                  <Text
                    style={{
                      color: colorPrimary,
                      cursor: "pointer",
                    }}
                    onClick={() => setShowAdditionalDCFilters(true)}>
                    Optimize List
                  </Text>
                </Col>

                <Col
                  xs={24}
                  sm={12}
                  md={6}
                  lg={6}
                  xl={6}
                  xxl={6}
                  hidden={!showAdditionalDCFilters}
                  aria-hidden={!showAdditionalDCFilters}>
                  <Form.Item
                    name={`subregion`}
                    label={`Distribution Center Sub Region`}>
                    <Select
                      suffixIcon={
                        <PiCaretDownLight size={12} color="#292929" />
                      }
                      placeholder="Select"
                      allowClear
                      showSearch
                      optionFilterProp="children"
                      filterOption={filterOptions}>
                      {dcFilterOptions.subregion.map((option) => {
                        const key = Object.keys(option)[0];
                        const label = Object.values(option)[0];

                        return (
                          <Select.Option key={key} value={key} label={label}>
                            {label}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Col>

                <Col
                  xs={24}
                  sm={12}
                  md={6}
                  lg={6}
                  xl={6}
                  xxl={6}
                  hidden={!showAdditionalDCFilters}
                  aria-hidden={!showAdditionalDCFilters}>
                  <Form.Item name={`country`} label={`Distribution Country`}>
                    <Select
                      suffixIcon={
                        <PiCaretDownLight size={12} color="#292929" />
                      }
                      placeholder="Select"
                      allowClear
                      showSearch
                      optionFilterProp="children"
                      filterOption={filterOptions}>
                      {dcFilterOptions.country.map((option) => {
                        const key = Object.keys(option)[0];
                        const label = Object.values(option)[0];

                        return (
                          <Select.Option key={key} value={key} label={label}>
                            {label}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Col>

                <Col
                  xs={24}
                  sm={12}
                  md={6}
                  lg={6}
                  xl={6}
                  xxl={6}
                  hidden={!showAdditionalDCFilters}
                  aria-hidden={!showAdditionalDCFilters}>
                  <Form.Item
                    name={`distributionCenter`}
                    label={`Distribution Center`}>
                    <Select
                      suffixIcon={
                        <PiCaretDownLight size={12} color="#292929" />
                      }
                      placeholder="Select"
                      allowClear
                      showSearch
                      optionFilterProp="children"
                      filterOption={filterOptions}>
                      {dcFilterOptions.distributionCenter.map((option) => {
                        const key = Object.keys(option)[0];
                        const label = Object.values(option)[0];

                        return (
                          <Select.Option key={key} value={key} label={label}>
                            {label}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </div>
          </Form>
        </div>

        <div>
          <Space>
            <Button
              type="primary"
              form="sku_search_filters"
              key="submit"
              htmlType="submit"
              onClick={handleSubmit}>
              Search
            </Button>
            <Button onClick={handleReset}>Reset</Button>
          </Space>
        </div>
      </Spin>
    </div>
  );
}

export default withAITracking(reactPlugin, NewSearch);

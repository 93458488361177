import { notification } from "antd";

export const notify = (type, message, description) => {
  // type = "success" | "info" | "warning" | "error"
  notification[type]({
    message,
    description,
    duration: 3,
  });
};

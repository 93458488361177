import {
  Button,
  Card,
  Col,
  Form,
  Row,
  Select,
  Space,
  Spin,
  Typography,
  theme,
} from "antd";
import { debounce } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { PiCaretDownLight } from "react-icons/pi";
import { useDispatch, useSelector } from "react-redux";
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";

import {
  fetchDefaultFilterOptionsForDC,
  fetchDefaultFilterOptionsForSKU,
  fetchFilterOptionsForDC,
  fetchFilterOptionsForSKU,
  fetchFilterOptionsForSKUBySearch,
} from "../../../redux/reducer/searchSlice";
import { getPathPrefix } from "../../../services/utilService";

const { Title, Text } = Typography;
const debounceTimeout = 500;

function MiniLoader() {
  return (
    <Space
      style={{
        height: "40px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
      align="center">
      <Spin size="small" />
    </Space>
  );
}

function Header() {
  const {
    token: { colorPrimary },
  } = theme.useToken();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [skuForm] = Form.useForm();
  const [dcForm] = Form.useForm();
  
  const {
    isSKUFiltersLoading,
    isSKUFilterOptionsLoading,
    isDCFiltersLoading,
    skuFilterOptions,
    dcFilterOptions,
  } = useSelector((state) => state.search);
  const { compareTableData } = useSelector((state) => state.compareTable);
  const [showAdditionalSKUFilters, setShowAdditionalSKUFilters] =
    useState(false);
  const [showAdditionalDCFilters, setShowAdditionalDCFilters] = useState(
    searchParams.get("distributionCenter") !== "null" ? true : false
  );

  const [selectedOptions, setSelectedOptions] = useState([]);
  useEffect(() => {
    dispatch(
      fetchFilterOptionsForSKU({ product: searchParams.get("product") })
    );
    
    if (searchParams.get("distributionCenter") !== "null") {
      dispatch(
        fetchFilterOptionsForDC({
          distributionCenter: searchParams.get("distributionCenter"),
        })
      );
    } else {
      dispatch(fetchDefaultFilterOptionsForDC());
    }
  }, [dispatch, searchParams]);

  useEffect(() => {
    
    const skuFormValues = {
      brand:
        skuFilterOptions?.Brand?.length === 1
          ? Object.keys(skuFilterOptions.Brand[0])[0]
          : null,
      category:
        skuFilterOptions?.Category?.length === 1
          ? Object.keys(skuFilterOptions.Category[0])[0]
          : null,
      centerRegion:
        skuFilterOptions?.CenterRegion?.length === 1
          ? Object.keys(skuFilterOptions.CenterRegion[0])[0]
          : null,
      country:
        skuFilterOptions?.Country?.length === 1
          ? Object.keys(skuFilterOptions.Country[0])[0]
          : null,
      division:
        skuFilterOptions?.Division?.length === 1
          ? Object.keys(skuFilterOptions.Division[0])[0]
          : null,
      form:
        skuFilterOptions?.Form_Selected?.length >0
          ?  skuFilterOptions.Form_Selected.map(ss => Object.keys(ss)[0])
          : [],
      product:
        skuFilterOptions?.Product?.length === 1
          ? Object.keys(skuFilterOptions.Product[0])[0]
          : null,
      subSector:
        skuFilterOptions?.Subsector_Selected?.length > 0 
          ? skuFilterOptions.Subsector_Selected.map(ss => Object.keys(ss)[0])
          : [],
      subregion:
        skuFilterOptions?.SubRegion?.length === 1
          ? Object.keys(skuFilterOptions.SubRegion[0])[0]
          : null,
    };
    skuForm.setFieldsValue(skuFormValues);
  }, [skuFilterOptions, skuForm]);
  
  useEffect(() => {
    const dcFormValues = {
      centerRegion:
        dcFilterOptions.centerRegion.length === 1
          ? Object.keys(dcFilterOptions.centerRegion[0])[0]
          : null,
      subregion:
        dcFilterOptions.subregion.length === 1
          ? Object.keys(dcFilterOptions.subregion[0])[0]
          : null,
      country:
        dcFilterOptions.country.length === 1
          ? Object.keys(dcFilterOptions.country[0])[0]
          : null,
      distributionCenter:
        dcFilterOptions.distributionCenter.length === 1
          ? Object.keys(dcFilterOptions.distributionCenter[0])[0]
          : null,
    };
    dcForm.setFieldsValue(dcFormValues);
  }, [dcFilterOptions, dcForm]);

  const onSKUFilterFormValuesChange = (changedValues, values) => {
    dispatch(fetchFilterOptionsForSKU(values));
  };

  const onDCFilterFormValuesChange = (changedValues, values) => {
    dispatch(fetchFilterOptionsForDC(values));
  };

  const handleSubmit = () => {
    if (
      skuForm.getFieldsError().filter((o) => o.errors.length > 0).length === 0
    ) {
      const skuFormValues = skuForm.getFieldsValue();
      const dcFormValues = dcForm.getFieldsValue();

      searchParams.set("product", skuFormValues.product);
      searchParams.set(
        "productDesc",
        skuFilterOptions?.Product?.find(
          (option) => option[skuFormValues.product]
        )[skuFormValues.product]
      );
      searchParams.set("brand", skuFormValues.brand || null);
      searchParams.set("division", skuFormValues.division || null);
      searchParams.set(
        "distributionCenter",
        dcFormValues.distributionCenter || null
      );

      navigate({
        pathname: getPathPrefix("/compare/sourcing-units-list"),
        search: `?${createSearchParams(searchParams)}`,
      });
    }
  };

  const filterOptions = (input, option) =>
    (option?.label?.toLowerCase() ?? "").includes(input.toLowerCase());

  const debouncedSearch = useRef(
    debounce(async (searchKey, filter) => {
      await dispatch(
        fetchFilterOptionsForSKUBySearch({
          searchKey,
          ...filter,
        })
      );
    }, debounceTimeout)
  ).current;

  const handleOnSearch = (searchKey, filter) => {
    debouncedSearch(searchKey, filter);
  };

  function handleSelect(data) {
    setSelectedOptions(data);
  }

  const handleReset = () => {
    skuForm.resetFields();
    dcForm.resetFields();
    dispatch(fetchDefaultFilterOptionsForSKU());
    dispatch(fetchDefaultFilterOptionsForDC());
  };
  
  return (

    <Card style={{ margin: "-24px 36px 0 24px", minHeight: 144 }} bordered>
      <Spin spinning={isSKUFiltersLoading || isDCFiltersLoading}>
        <Row align="bottom" gutter={[16, 16]}>
          <Col xs={24} sm={12} md={8} lg={6} xl={6} xxl={6}>
            <Title level={3}>{searchParams.get("productDesc")}</Title>
            <Text italic style={{ fontSize: 14 }}>
              {compareTableData?.Alternative?.length || "No"} Sourcing Units
              found producing this SKU
            </Text>
          </Col>
          <Col xs={24} sm={12} md={16} lg={18} xl={18} xxl={18}>
            <div>
              <Form
                name="sku_search_filters"
                form={skuForm}
                layout="vertical"
                size="small"
                autoComplete="off"
                requiredMark
                initialValues={{
                  brand: null,
                  category: null,
                  centerRegion: null,
                  country: null,
                  division: null,
                  form: [],
                  product: null,
                  subSector: [],
                  subregion: null,
                }}
                onValuesChange={onSKUFilterFormValuesChange}>
                <div>
                  <Row gutter={[8, 0]} align="middle">
                    <Col xs={24} sm={12} md={8} lg={6} xl={6} xxl={6}>
                      <Form.Item
                        name={`product`}
                        label={`SKU (DU Code) or the Description`}
                        rules={[{ required: true }]}>
                        <Select
                          suffixIcon={
                            <PiCaretDownLight size={12} color="#292929" />
                          }
                          placeholder="Select"
                          allowClear
                          showSearch
                          filterOption={false}
                          onSearch={(value) =>
                            handleOnSearch("product", { sku: value })
                          }
                          dropdownRender={(menu) => (
                            <>
                              {isSKUFilterOptionsLoading ? (
                                <MiniLoader />
                              ) : (
                                menu
                              )}
                            </>
                          )}>
                          {skuFilterOptions?.Product?.map((option) => {
                            const key = Object.keys(option)[0];
                            const label = Object.values(option)[0];

                            return (
                              <Select.Option
                                key={key}
                                value={key}
                                label={label}>
                                {label}
                              </Select.Option>
                            );
                          })}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col
                      xs={24}
                      sm={12}
                      md={8}
                      lg={6}
                      xl={6}
                      xxl={6}
                      hidden={showAdditionalSKUFilters}
                      aria-hidden={showAdditionalSKUFilters}>
                      <Text>
                        <span
                          style={{
                            color: colorPrimary,
                            cursor: "pointer",
                          }}
                          onClick={() => setShowAdditionalSKUFilters(true)}>
                          Optimize List
                        </span>
                      </Text>
                    </Col>
                  </Row>
                  <div
                    hidden={!showAdditionalSKUFilters}
                    aria-hidden={!showAdditionalSKUFilters}>
                    <Text
                      strong
                      style={{
                        color: "#7D7D7D",
                      }}>
                      Use the filters below to create a short list of DUs to
                      select in the field above.
                    </Text>

                    <Row align="bottom" gutter={[8, 8]}>
                      <Col xs={12} sm={8} md={6} lg={3} xl={3} xxl={3}>
                        <Form.Item name={`division`} label={`Business Group`}>
                          <Select
                            suffixIcon={
                              <PiCaretDownLight size={12} color="#292929" />
                            }
                            placeholder="Select"
                            allowClear
                            showSearch
                            filterOption={false}
                            onSearch={(value) =>
                              handleOnSearch("division", { division: value })
                            }
                            dropdownRender={(menu) => (
                              <>
                                {isSKUFilterOptionsLoading ? (
                                  <MiniLoader />
                                ) : (
                                  menu
                                )}
                              </>
                            )}>
                            {skuFilterOptions?.Division?.map((option) => {
                              const key = Object.keys(option)[0];
                              const label = Object.values(option)[0];

                              return (
                                <Select.Option
                                  key={key}
                                  value={key}
                                  label={label}>
                                  {label}
                                </Select.Option>
                              );
                            })}
                          </Select>
                        </Form.Item>
                      </Col>

                      <Col xs={12} sm={8} md={6} lg={3} xl={3} xxl={3}>
                        <Form.Item name={`category`} label={`Category`}>
                          <Select
                            suffixIcon={
                              <PiCaretDownLight size={12} color="#292929" />
                            }
                            placeholder="Select"
                            allowClear
                            showSearch
                            filterOption={false}
                            onSearch={(value) =>
                              handleOnSearch("category", { category: value })
                            }
                            dropdownRender={(menu) => (
                              <>
                                {isSKUFilterOptionsLoading ? (
                                  <MiniLoader />
                                ) : (
                                  menu
                                )}
                              </>
                            )}>
                            {skuFilterOptions?.Category?.map((option) => {
                              const key = Object.keys(option)[0];
                              const label = Object.values(option)[0];

                              return (
                                <Select.Option
                                  key={key}
                                  value={key}
                                  label={label}>
                                  {label}
                                </Select.Option>
                              );
                            })}
                          </Select>
                        </Form.Item>
                      </Col>
                      
                      <Col xs={12} sm={8} md={6} lg={3} xl={3} xxl={3}>
                        <Form.Item name={`subSector`} label={`Sub-Sector`}>
                          <Select
                            suffixIcon={
                              <PiCaretDownLight size={12} color="#292929" />
                            }
                            placeholder="Select"
                            // allowClear
                            showSearch
                            filterOption={false}
                            onSearch={(value) =>
                              handleOnSearch("subSector", { subSector: value })
                            }
                            dropdownRender={(menu) => (
                              <>
                                {isSKUFilterOptionsLoading ? (
                                  <MiniLoader />
                                ) : (
                                  menu
                                )}
                              </>
                            )}
                            mode="multiple"
                            >
                            {skuFilterOptions?.Subsector?.map((option) => {
                              const key = Object.keys(option)[0];
                              const label = Object.values(option)[0];
                              return (
                                <Select.Option
                                  key={key}
                                  value={key}
                                  label={label}>
                                  {label}
                                </Select.Option>
                              );
                            })}
                          </Select>
                        </Form.Item>
                      </Col>

                      <Col xs={12} sm={8} md={6} lg={3} xl={3} xxl={3}>
                        <Form.Item name={`form`} label={`Product Form Name`}>
                          <Select
                            suffixIcon={
                              <PiCaretDownLight size={12} color="#292929" />
                            }
                            placeholder="Select"
                            // allowClear
                            showSearch
                            filterOption={false}
                            onSearch={(value) =>
                              handleOnSearch("form", { form: value })
                            }
                            dropdownRender={(menu) => (
                              <>
                                {isSKUFilterOptionsLoading ? (
                                  <MiniLoader />
                                ) : (
                                  menu
                                )}
                              </>
                            )}
                            mode="multiple"
                            >
                            {skuFilterOptions?.Form?.map((option) => {
                              const key = Object.keys(option)[0];
                              const label = Object.values(option)[0];

                              return (
                                <Select.Option
                                  key={key}
                                  value={key}
                                  label={label}>
                                  {label}
                                </Select.Option>
                              );
                            })}
                          </Select>
                        </Form.Item>
                      </Col>

                      <Col xs={12} sm={8} md={6} lg={3} xl={3} xxl={3}>
                        <Form.Item name={`brand`} label={`Brand`}>
                          <Select
                            suffixIcon={
                              <PiCaretDownLight size={12} color="#292929" />
                            }
                            placeholder="Select"
                            allowClear
                            showSearch
                            filterOption={false}
                            onSearch={(value) =>
                              handleOnSearch("brand", { brand: value })
                            }
                            dropdownRender={(menu) => (
                              <>
                                {isSKUFilterOptionsLoading ? (
                                  <MiniLoader />
                                ) : (
                                  menu
                                )}
                              </>
                            )}>
                            {skuFilterOptions?.Brand?.map((option) => {
                              const key = Object.keys(option)[0];
                              const label = Object.values(option)[0];

                              return (
                                <Select.Option
                                  key={key}
                                  value={key}
                                  label={label}>
                                  {label}
                                </Select.Option>
                              );
                            })}
                          </Select>
                        </Form.Item>
                      </Col>

                      <Col xs={12} sm={8} md={6} lg={3} xl={3} xxl={3}>
                        <Form.Item
                          name={`centerRegion`}
                          label={`Sourcing Unit Region`}>
                          <Select
                            suffixIcon={
                              <PiCaretDownLight size={12} color="#292929" />
                            }
                            placeholder="Select"
                            allowClear
                            showSearch
                            filterOption={false}
                            onSearch={(value) =>
                              handleOnSearch("centerRegion", {
                                centerRegion: value,
                              })
                            }
                            dropdownRender={(menu) => (
                              <>
                                {isSKUFilterOptionsLoading ? (
                                  <MiniLoader />
                                ) : (
                                  menu
                                )}
                              </>
                            )}>
                            {skuFilterOptions?.CenterRegion?.map((option) => {
                              const key = Object.keys(option)[0];
                              const label = Object.values(option)[0];

                              return (
                                <Select.Option
                                  key={key}
                                  value={key}
                                  label={label}>
                                  {label}
                                </Select.Option>
                              );
                            })}
                          </Select>
                        </Form.Item>
                      </Col>

                      <Col xs={12} sm={8} md={6} lg={3} xl={3} xxl={3}>
                        <Form.Item
                          name={`subregion`}
                          label={`Sourcing Unit Sub Region`}>
                          <Select
                            suffixIcon={
                              <PiCaretDownLight size={12} color="#292929" />
                            }
                            placeholder="Select"
                            allowClear
                            showSearch
                            filterOption={false}
                            onSearch={(value) =>
                              handleOnSearch("subregion", { subregion: value })
                            }
                            dropdownRender={(menu) => (
                              <>
                                {isSKUFilterOptionsLoading ? (
                                  <MiniLoader />
                                ) : (
                                  menu
                                )}
                              </>
                            )}>
                            {skuFilterOptions?.SubRegion?.map((option) => {
                              const key = Object.keys(option)[0];
                              const label = Object.values(option)[0];

                              return (
                                <Select.Option
                                  key={key}
                                  value={key}
                                  label={label}>
                                  {label}
                                </Select.Option>
                              );
                            })}
                          </Select>
                        </Form.Item>
                      </Col>

                      <Col xs={12} sm={8} md={6} lg={3} xl={3} xxl={3}>
                        <Form.Item
                          name={`country`}
                          label={`Sourcing Unit Country`}>
                          <Select
                            suffixIcon={
                              <PiCaretDownLight size={12} color="#292929" />
                            }
                            placeholder="Select"
                            allowClear
                            showSearch
                            filterOption={false}
                            onSearch={(value) =>
                              handleOnSearch("country", { country: value })
                            }
                            dropdownRender={(menu) => (
                              <>
                                {isSKUFilterOptionsLoading ? (
                                  <MiniLoader />
                                ) : (
                                  menu
                                )}
                              </>
                            )}>
                            {skuFilterOptions?.Country?.map((option) => {
                              const key = Object.keys(option)[0];
                              const label = Object.values(option)[0];

                              return (
                                <Select.Option
                                  key={key}
                                  value={key}
                                  label={label}>
                                  {label}
                                </Select.Option>
                              );
                            })}
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Form>
              <Row gutter={[8, 8]} align="bottom">
                <Col xs={24} sm={12} md={21} lg={21} xl={21} xxl={21}>
                  <Form
                    name="dc_search_filters"
                    form={dcForm}
                    layout="vertical"
                    size="small"
                    autoComplete="off"
                    initialValues={{
                      centerRegion: null,
                      subregion: null,
                      country: null,
                      distributionCenter: null,
                    }}
                    onValuesChange={onDCFilterFormValuesChange}>
                    <Row gutter={[8, 8]} align="bottom">
                      <Col xs={24} sm={12} md={6} lg={6} xl={6} xxl={6}>
                        <Form.Item
                          name={`centerRegion`}
                          label={`Distribution Center Region`}
                          style={{ marginBottom: 0 }}>
                          <Select
                            suffixIcon={
                              <PiCaretDownLight size={12} color="#292929" />
                            }
                            placeholder="Select"
                            allowClear
                            showSearch
                            optionFilterProp="children"
                            filterOption={filterOptions}>
                            {dcFilterOptions?.centerRegion?.map((option) => {
                              const key = Object.keys(option)[0];
                              const label = Object.values(option)[0];

                              return (
                                <Select.Option
                                  key={key}
                                  value={key}
                                  label={label}>
                                  {label}
                                </Select.Option>
                              );
                            })}
                          </Select>
                        </Form.Item>
                      </Col>

                      <Col
                        xs={24}
                        sm={12}
                        md={6}
                        lg={6}
                        xl={6}
                        xxl={6}
                        hidden={showAdditionalDCFilters}
                        aria-hidden={showAdditionalDCFilters}
                        style={{ paddingBottom: 2 }}>
                        <Text
                          style={{
                            color: colorPrimary,
                            cursor: "pointer",
                          }}
                          onClick={() => setShowAdditionalDCFilters(true)}>
                          Optimize List
                        </Text>
                      </Col>

                      <Col
                        xs={24}
                        sm={12}
                        md={6}
                        lg={6}
                        xl={6}
                        xxl={6}
                        hidden={!showAdditionalDCFilters}
                        aria-hidden={!showAdditionalDCFilters}>
                        <Form.Item
                          name={`subregion`}
                          label={`Distribution Center Sub Region`}
                          style={{ marginBottom: 0 }}>
                          <Select
                            suffixIcon={
                              <PiCaretDownLight size={12} color="#292929" />
                            }
                            placeholder="Select"
                            allowClear
                            showSearch
                            optionFilterProp="children"
                            filterOption={filterOptions}>
                            {dcFilterOptions?.subregion?.map((option) => {
                              const key = Object.keys(option)[0];
                              const label = Object.values(option)[0];

                              return (
                                <Select.Option
                                  key={key}
                                  value={key}
                                  label={label}>
                                  {label}
                                </Select.Option>
                              );
                            })}
                          </Select>
                        </Form.Item>
                      </Col>

                      <Col
                        xs={24}
                        sm={12}
                        md={6}
                        lg={6}
                        xl={6}
                        xxl={6}
                        hidden={!showAdditionalDCFilters}
                        aria-hidden={!showAdditionalDCFilters}>
                        <Form.Item
                          name={`country`}
                          label={`Distribution Country`}
                          style={{ marginBottom: 0 }}>
                          <Select
                            suffixIcon={
                              <PiCaretDownLight size={12} color="#292929" />
                            }
                            placeholder="Select"
                            allowClear
                            showSearch
                            optionFilterProp="children"
                            filterOption={filterOptions}>
                            {dcFilterOptions.country.map((option) => {
                              const key = Object.keys(option)[0];
                              const label = Object.values(option)[0];

                              return (
                                <Select.Option
                                  key={key}
                                  value={key}
                                  label={label}>
                                  {label}
                                </Select.Option>
                              );
                            })}
                          </Select>
                        </Form.Item>
                      </Col>

                      <Col
                        xs={24}
                        sm={12}
                        md={6}
                        lg={6}
                        xl={6}
                        xxl={6}
                        hidden={!showAdditionalDCFilters}
                        aria-hidden={!showAdditionalDCFilters}>
                        <Form.Item
                          name={`distributionCenter`}
                          label={`Distribution Center`}
                          style={{ marginBottom: 0 }}>
                          <Select
                            suffixIcon={
                              <PiCaretDownLight size={12} color="#292929" />
                            }
                            placeholder="Select"
                            allowClear
                            showSearch
                            optionFilterProp="children"
                            filterOption={filterOptions}>
                            {dcFilterOptions.distributionCenter.map(
                              (option) => {
                                const key = Object.keys(option)[0];
                                const label = Object.values(option)[0];

                                return (
                                  <Select.Option
                                    key={key}
                                    value={key}
                                    label={label}>
                                    {label}
                                  </Select.Option>
                                );
                              }
                            )}
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Form>
                </Col>

                <Col xs={24} sm={12} md={3} lg={3} xl={3} xxl={3}>
                  <Space
                    style={{ display: "flex", justifyContent: "flex-end" }}>
                    <Button size="small" onClick={handleReset}>
                      Reset
                    </Button>
                    <Button
                      type="primary"
                      size="small"
                      form="sku_search_filters"
                      key="submit"
                      htmlType="submit"
                      onClick={handleSubmit}>
                      Search
                    </Button>
                  </Space>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Spin>
    </Card>
  );
}

export default Header;

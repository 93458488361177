import { Card, Col, Row, Spin, Typography, Tooltip } from "antd";
import React from "react";
import { MdOutlineKeyboardBackspace } from "react-icons/md";
import { useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import RedDot from "../../../assets/img/red-dot.svg"

const { Title, Text, Paragraph } = Typography;

const deletionCss = {
  color: "rgb(0, 0, 0)",
  border: "1px solid #ec6157",
  background: "#FFF7F6",
  borderLeft: "4px solid #ec6157",
  borderRadius: "0px 4px 4px 0px",
  width: "max-content"
}

function Header() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const { isComparisonDataLoading, comparisonData, compareTableData, comparisonCostData } = useSelector(
    (state) => state.compareTable
  );

  const MarkedForDeletion = () => {
    return <Tooltip overlayInnerStyle={deletionCss} placement="right" arrow={false} title="Marked for Deletion" key="#ec6157">
      <img
        src={RedDot}
        alt="deletion-flag"
        style={{ marginLeft: 8 }}
      />
    </Tooltip>
  }

  return (
    <Card style={{ margin: "-24px 36px 0 24px", minHeight: 160 }} bordered>
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={12} md={8} lg={6} xl={6} xxl={6}>
          <Text
            strong
            onClick={() => navigate(-1)}
            style={{ cursor: "pointer", fontSize: 10 }}>
            <MdOutlineKeyboardBackspace
              style={{ fontSize: 16, marginRight: 8, marginBottom: -4 }}
            />
            GO BACK
          </Text>
          <br />
          <br />
          <br />
          <Text strong style={{ fontSize: 12, color: "#797979" }}>
            TARGET SKU
          </Text>
          <Title level={3} style={{ marginTop: 0 }}>
            {searchParams.get("skuDescription")}
            {compareTableData.FinishedGoodDeletionIndicator === 'X' ? <MarkedForDeletion /> : null}
          </Title>
          {comparisonCostData?.plantName && <Text strong>Distribution Center :  {comparisonCostData?.plantName}</Text>
          }
        </Col>

        <Col xs={24} sm={12} md={16} lg={18} xl={18} xxl={18}>
          <Spin spinning={isComparisonDataLoading}>
            <Row gutter={[16, 8]}>
              <Col xs={24} sm={12} md={8} lg={8} xl={8} xxl={8}>
                <Text strong>Pack Size</Text>

                <Paragraph>
                  {comparisonData?.alternativeIntialData?.targetPlant?.target1
                    ?.Dimension || "(0 NA / 0 NA)"}
                </Paragraph>
              </Col>
              <Col xs={24} sm={12} md={8} lg={8} xl={8} xxl={8}>
                <Text strong>
                  Raw Material (
                  {comparisonData?.alternativeIntialData?.targetPlant?.target1
                    ?.rawMaterial?.length || 0}
                  )
                </Text>

                <Paragraph>
                  <ul
                    style={{
                      height: 120,
                      overflow: "auto",
                      listStyleType: "disc",
                    }}>
                    {comparisonData?.alternativeIntialData?.targetPlant?.target1?.rawMaterial?.map(
                      (obj) => <li>{obj}</li>
                    ) || "-"}
                  </ul>
                </Paragraph>
              </Col>
              <Col xs={24} sm={12} md={8} lg={8} xl={8} xxl={8}>
                <Text strong>
                  Pack. Material (
                  {comparisonData?.alternativeIntialData?.targetPlant?.target1
                    ?.packagingMaterial?.length || 0}
                  )
                </Text>

                <Paragraph>
                  <ul
                    style={{
                      height: 120,
                      overflow: "auto",
                      listStyleType: "disc",
                    }}>
                    {comparisonData?.alternativeIntialData?.targetPlant?.target1?.packagingMaterial?.map(
                      (obj) => <li>{obj}</li>
                    ) || "-"}
                  </ul>
                </Paragraph>
              </Col>
            </Row>
          </Spin>
        </Col>
      </Row>
    </Card>
  );
}

export default Header;

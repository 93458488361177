import CryptoJS from "crypto-js";
import Papa from "papaparse";
import { ReactSession } from "react-client-session";

import { msalConfig } from "../sso/authConfig";

const BASE_URL = process.env.REACT_APP_BASE_URL;

export const encrypt = (string) => {
  const key1 = "AAAAAAAAAAAAAAAA";
  const key2 = "BBBBBBBBBBBBBBBB";

  const key = CryptoJS.enc.Utf8.parse(key1);
  const iv = CryptoJS.enc.Utf8.parse(key2);

  const encrypted = CryptoJS.AES.encrypt(string, key, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
  });
  return encrypted.toString();
};

export const getAccessToken = () => {
  const msalTokenKeys = JSON.parse(
    sessionStorage.getItem("msal.token.keys." + msalConfig.auth.clientId) ||
    "{}"
  );
  const accessToken = msalTokenKeys?.accessToken?.[0];
  const accessTokenValues = JSON.parse(
    sessionStorage.getItem(accessToken) || "{}"
  );

  return accessTokenValues.secret || "";
};

export const getUserDetails = () => {
  const msalTokenKeys = JSON.parse(
    sessionStorage.getItem("msal.token.keys." + msalConfig.auth.clientId) ||
    "{}"
  );

  const accessToken = msalTokenKeys?.accessToken?.[0];
  const accessTokenValues = JSON.parse(
    sessionStorage.getItem(accessToken) || "{}"
  );

  const idToken =
    accessTokenValues.homeAccountId +
    "-" +
    accessTokenValues.environment +
    "-" +
    accessTokenValues.realm;
  const idTokenValues = JSON.parse(sessionStorage.getItem(idToken) || "{}");

  return {
    name: idTokenValues.name || "",
    email: idTokenValues.username || "",
    role: idTokenValues.idTokenClaims.roles[0] || "",
  };
};

export const getHeaders = () => {
  return {
    authorization: "Bearer " + getAccessToken(),
    from: encrypt(ReactSession.get("email")),
    to: ReactSession.get("role"),
    "x-frame-options": `allow-from ${BASE_URL}`,
    "Content-Type": "application/json",
  };
};

export const responseFormatter = async (apiResponse) => {
  if (!apiResponse.ok) {
    // logout
    if (apiResponse.status === 440) {
      sessionStorage.clear();
      window.location.reload();
    }

    return {
      success: false,
      data: null,
      error: {
        message: `An error has occured: ${apiResponse.status}`,
      },
    };
  }

  const data = await apiResponse.json();

  return {
    success: true,
    data: data,
    error: null,
  };
};

export const getUTFOptions = () => {
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth();
  const dataCurrentYear = [];
  const dataMiddleYears = [];

  for (let i = currentMonth + 1; i > 0; i--) {
    dataCurrentYear.push({
      label: `${i} ${currentYear}`,
      value: `${i} ${currentYear}`,
    });
  }

  for (let i = currentYear - 1; i > 2021; i--) {
    for (let j = 12; j > 0; j--) {
      dataMiddleYears.push({
        label: `${j} ${i}`,
        value: `${j} ${i}`,
      });
    }
  }

  const data2021 = [
    { label: "12 2021", value: "12 2021" },
    { label: "11 2021", value: "11 2021" },
    { label: "10 2021", value: "10 2021" },
    { label: "9 2021", value: "9 2021" },
    { label: "8 2021", value: "8 2021" },
  ];

  return [...dataCurrentYear, ...dataMiddleYears, ...data2021];
};

export const downloadCSV = (data, filename) => {
  const csvData = Papa.unparse(data);
  const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  a.download = filename + ".csv";
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};

export const convertToCurrencyFormat = (value, format = "EUR") => {
  if (isNaN(value)) {
    return value;
  }

  return new Intl.NumberFormat(navigator.language, {
    style: "currency",
    currency: format,
  }).format(value);
};

export const isPilot = () => {
  return true;
  return window.location.pathname.startsWith("/pilot/");
};

// to get the url for pilot
export const getPathPrefix = (pathname) => {
  if (window.location.pathname.startsWith("/pilot/")) {
    return "/pilot" + pathname;
  }

  return pathname;
};

export const formatDays = (numDays) => {
  const years = Math.floor(numDays / 365);
  const remainingDaysWithoutYears = numDays % 365;
  const weeks = Math.floor(remainingDaysWithoutYears / 7);
  const remainingDays = remainingDaysWithoutYears % 7;

  let result = '';

  if (years > 0) {
    result += `${years} year${years > 1 ? 's' : ''} `;
  }

  if (weeks > 0) {
    result += `${weeks} week${weeks > 1 ? 's' : ''} `;
  }

  if (remainingDays > 0) {
    result += `${remainingDays} day${remainingDays > 1 ? 's' : ''}`;
  }

  return result.trim();
}

export function removeDuplicates(array, property) {
  const seen = new Set();
  return array.filter(obj => {
    const propertyValue = obj[property];
    if (!seen.has(propertyValue)) {
      seen.add(propertyValue);
      return true;
    }
    return false;
  });
}


export function alterateAlternativeResult(data) {
  return data.reduce((acc, obj) => {
    let existingEntry = acc.find(entry => (entry.PlantCode === obj.PlantCode) && entry.PlantName === obj.PlantName);

    if (existingEntry) {
      existingEntry.MatchBrand.push(obj.MatchBrand);
    } else {
      acc.push({ ...obj, MatchBrand: [obj.MatchBrand] });
    }

    return acc;
  }, []);
}